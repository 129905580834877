import DialogModal from "../dialog";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAffiliatesPromoCodesApplyMutation } from "../../api/query";
import { IRemoteError, RemoteErrorType, ServerFormError } from "../../types/errors";
import { IPromoCodeApplyRequest } from "../../types/affiliates";

interface Props {
    onClose: () => void;
}

export default function PromoCodeModal({ onClose }: Props) {
    const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IPromoCodeApplyRequest>();

    const { mutate, error } = useAffiliatesPromoCodesApplyMutation();

    const onSubmit = (data: IPromoCodeApplyRequest) => {
        setRemoteError(null);
        mutate(data, {
            onSuccess: () => {
                onClose();
            }
        });
    }

    useEffect(() => {
        if (error) {
            if (error instanceof ServerFormError) {
                const rootError = error.getErrorForField('body');
                if (rootError) {
                    setRemoteError(rootError);
                }
            } else {
                setRemoteError(ServerFormError.unknown());
            }
        }
    }, [error])

    return (
        <DialogModal
            title={t('billing.affiliates.promo_codes.activate.title')}
            onClose={() => onClose()}
        >
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                {remoteError && (
                    <div
                        className="m-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                        role="alert"
                    >
                        {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`)}
                    </div>
                )}
                <div className="px-4 mt-4 mb-4">
                    <div className="relative">
                        <input
                            type="text"
                            id="code"
                            className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                            placeholder={" "}
                            aria-invalid={!!errors.code}
                            autoComplete="Off"
                            {...register('code', {
                                required: String(t('errors.local.required_field')),
                            })}
                        />
                        <label
                            htmlFor="code"
                            className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                        >
                            {t('billing.affiliates.promo_codes.activate.modal.label')}
                        </label>
                    </div>
                    {errors.code && (
                        <span role="alert" className="mt-1 text-sm text-red-600">
                            {errors.code.message}
                        </span>
                    )}
                </div>
                <div className="flex justify-end gap-2 px-4 mb-4">
                    <button
                        className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
                        type="submit"
                    >
                        {t('billing.affiliates.promo_codes.activate.modal.button')}
                    </button>
                    <button
                        className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-gray-600 hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
                        onClick={() => onClose()}
                    >
                        {t('common.cancel')}
                    </button>

                </div>
            </form>
        </DialogModal>
    )
}