import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPromoCodeResponse } from "../../types/affiliates";
import { useAffiliatePromoCodesListQuery } from "../../api/query";
import { CheckIcon, NoSymbolIcon, PlusIcon } from "@heroicons/react/24/outline";
import CreatePromoCodeModal from "./CreatePromoCodeModal";


const PromoCodes = () => {
    const [promoCodes, setPromoCodes] = useState<IPromoCodeResponse[]>([]);
    const [showCreatePromoCodeModal, setShowCreatePromoCodeModal] = useState(false);

    const { data: promoCodesData } = useAffiliatePromoCodesListQuery();

    useEffect(() => {
        if (promoCodesData) {
            setPromoCodes(promoCodesData);
        }
    }, [promoCodesData]);

    const { t } = useTranslation();

    return (
        <div className="flex flex-col mb-6">
            <h3 className="text-3xl py-3">{t('affiliate.promo_codes.title')}</h3>
            <div className="overflow-x-auto md:overflow-x-hidden mb-2">
                <table className="w-full table-auto text-sm mb-2 whitespace-nowrap">
                    <thead className="text-left">
                        <tr className="border-b border-gray-200">
                            <th className="p-2 font-semibold">{t('affiliate.promo_codes.head.code')}</th>
                            <th className="p-2 font-semibold">{t('affiliate.promo_codes.head.from_date')}</th>
                            <th className="p-2 font-semibold">{t('affiliate.promo_codes.head.to_date')}</th>
                            <th className="p-2 font-semibold">{t('affiliate.promo_codes.head.discount')}</th>
                            <th className="p-2 font-semibold">{t('affiliate.promo_codes.head.multi_use')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-700 text-sm">
                        {promoCodes.map((promoCode, index) => (
                            <tr key={index} className="border-b border-gray-200">
                                <td className="p-2">{promoCode.code}</td>
                                <td className="p-2">{new Date(promoCode.from_date).toLocaleDateString()}</td>
                                <td className="p-2">{new Date(promoCode.to_date).toLocaleDateString()}</td>
                                <td className="p-2">{promoCode.amount}%</td>
                                <td className="p-2">{promoCode.multi_use ? <CheckIcon className="w-4 h-4" /> : <NoSymbolIcon className="w-4 h-4" />}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div>
                <button
                    className="flex gap-2 justify-center py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
                    onClick={() => setShowCreatePromoCodeModal(true)}
                >
                    <PlusIcon className="w-4 h-4" />
                    {t('affiliate.promo_codes.button.add')}
                </button>
            </div>
            {
                showCreatePromoCodeModal && (
                    <CreatePromoCodeModal onClose={() => setShowCreatePromoCodeModal(false)} />
                )
            }
        </div>
    )
};

export default PromoCodes;