import { Bars3Icon } from "@heroicons/react/24/outline";
import { useHomeContext } from "../../contexts/HomeContext";

interface Props {
    sidebarOpen: boolean;
    setSidebarOpen: (sidebarOpen: boolean) => void;
}

export default function MobileNav({ sidebarOpen, setSidebarOpen }: Props) {
    const { state: { title } } = useHomeContext();
    return (
        <div className="fixed left-0 right-0 top-0 z-10 flex items-center border-b border-gray-200 bg-white py-2 pl-1 text-gray-900 sm:pl-3 md:hidden">
            <button
                type="button"
                className="inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:hover:text-white"
                onClick={() => setSidebarOpen(!sidebarOpen)}
            >
                <Bars3Icon className="w-6 h-6" />
            </button>
            <h1 className="flex inset-0 items-center justify-center text-base font-normal overflow-hidden text-ellipsis break-all whitespace-nowrap absolute top-0 left-0 right-0 bottom-0 ml-11 mr-11">
                {title}
            </h1>
        </div>
    );
}