import { useEffect, useRef, useState } from "react";
import TopLinks from "./TopLinks";
import BottomLinks from "./BottomLinks";

interface Props {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

export default function Nav({ isOpen, setIsOpen }: Props) {
    const [isHovering, setIsHovering] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [sidebarClass, setSidebarClass] = useState('');
    const sidebarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setIsMobile(window.innerWidth < 768);
    }, [window.innerWidth]);

    useEffect(() => {
        if (isMobile) {
            setSidebarClass(`fixed top-0 left-0 bottom-0 z-20 w-full transition-transform ${isOpen ? '' : 'transform-0'}`);
        } else {
            setSidebarClass('');
        }
    }, [isOpen, isMobile]);

    useEffect(() => {
        if (!isMobile) {
            setIsOpen(true);
        }

        setIsOpen(false);
        const handleMouseDown = (e: MouseEvent) => {
            if (sidebarRef.current && !sidebarRef.current.contains(e.target as Node)) {
                window.addEventListener('mouseup', handleMouseUp);
            }
        };

        const handleMouseUp = (e: MouseEvent) => {
            window.removeEventListener('mouseup', handleMouseUp);
            setIsOpen(false);
        };

        window.addEventListener('mousedown', handleMouseDown);

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
        };
    }, [isMobile]);

    return (
        <>
            <div className={`nav active duration-200 ${sidebarClass}`}>
                <div className="w-[260px] border-r border-gray-200 bg-white h-full min-h-0" ref={sidebarRef}>
                    <div className="flex h-full flex-col">
                        <div className="scrollbar-trigger relative flex h-full w-full flex-1 items-start">
                            <nav className="relative flex h-full flex-1 flex-col space-y-1 p-2">
                                <div
                                    className={`flex-1 flex-col border-b border-gray-200 overflow-y-auto ${isHovering ? '' : 'scrollbar-transparent'}`}
                                    onMouseEnter={() => setIsHovering(true)}
                                    onMouseLeave={() => setIsHovering(false)}
                                >
                                    <img src="/images/logo.svg" alt="ProxyAPI Logo" className="h-5 w-auto mb-5 mt-3 pl-4" />
                                    <div className="flex flex-col gap-2 text-sm">
                                        <TopLinks setIsOpen={setIsOpen} />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 text-sm">
                                    <BottomLinks />
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
