export enum PromoCodeType {
    DISCOUNT = 'discount',
    CREDIT = 'credit',
}

export enum PaymentStatusType {
    PENDING = "pending",
    COMPLETED = "completed",
    REFUNDED = "refunded"
}

export interface IPromoCodeResponse {
    code: string;
    type: PromoCodeType;
    amount: number;
    from_date: string;
    to_date: string;
    multi_use: boolean;
}

export interface IPromoCodeUserResponse {
    payment_id?: number | null;
    promo_code: IPromoCodeResponse;
}

export interface IPromoCodeApplyRequest {
    code: string;
}

export interface IPromoCodeAffiliateCreateSchema {
    code: string;
    from_date: string;
    to_date: string;
    multi_use: boolean;
}

export interface IAffiliateJoinRequest {
    accept_terms: boolean;
}

export interface IAffiliateInfoResponseSchema {
    payout_commission: number;
    pending_payout: number;
    paid_payout: number;
}

export interface IAffiliatePayoutResponseSchema {
    id: number;
    amount: number;
    created_at: string;
}

export interface IAffiliatePaymentResponseSchema {
    id: number;
    payout_amount: number;
    payment_status: PaymentStatusType;
    payout: IAffiliatePayoutResponseSchema | null;
    created_at: string;
}