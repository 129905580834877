import axios from "axios";
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "react-query";

import jwtDecode from "jwt-decode";
import * as AffiliatesTypes from '../types/affiliates';
import * as AuthTypes from '../types/auth';
import { IServerErrorDetail, ServerFormError, UnauthorizedError, followsIServerErrorDetail } from "../types/errors";
import * as KeysTypes from '../types/keys';
import * as PaymentTypes from '../types/payment';
import { ISuccessResponse } from "../types/site";
import * as SubscriptionTypes from '../types/subscription';
import * as UsageTypes from '../types/usage';
import * as UserTypes from '../types/user';
import useStorage, { StorageWrapper } from "../utils/storage";
import { Endpoints, HttpMethod } from "./endpoints";

// axios instance with base url and accept header set to json
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

const getToken = async (storage: StorageWrapper): Promise<string | null> => {
  const token = storage.getItem('token');
  const refreshToken = storage.getItem('refreshToken');
  if (token && refreshToken) {
    const decodedToken: any = jwtDecode(token);
    // expired with 60 seconds buffer
    if (decodedToken.exp * 1000 < Date.now() + 60 * 1000) {
      const response = await axiosInstance.post(Endpoints.auth.refreshToken.path, null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      storage.setItem('token', response.data.token);
      return response.data.token;
    }
    return token;
  }
  throw new Error('No token found');
}


const request = async (
  url: string,
  method: HttpMethod,
  requiresAuth: boolean,
  storage: StorageWrapper,
  data?: any,
  params?: any,
): Promise<any> => {
  let headers = {};
  if (requiresAuth) {
    try {
      const token = await getToken(storage);
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    catch (error) {
      throw new UnauthorizedError('No token found');
    }
  }
  try {
    const response = await axiosInstance.request({
      url,
      method,
      data,
      params,
      headers,
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      const details = error.response.data as IServerErrorDetail;
      if (followsIServerErrorDetail(details)) {
        throw new ServerFormError(details, "An error occurred");
      }
    }
    throw new Error('An error occurred', { cause: error });
  }
};


enum QueryKeys {
  user = 'user',
  api_keys_list = 'api_keys_list',
  api_key_models_list = 'api_key_models_list',
  usage_list = 'usage_list',
  usage_aggregated = 'usage_aggregated',
  payment_list = 'payment_list',
  affiliates_promo_codes_me = 'affiliates_promo_codes_me',
  affiliates_me = 'affiliates_me',
  affiliates_payments_list = 'affiliates_payments_list',
  affiliates_payouts_list = 'affiliates_payouts_list',
  affiliates_promo_codes_list = 'affiliates_promo_codes_list',
  subscriptions = 'subscriptions',
}

export const useAuthRegisterMutation = () => {
  const queryClient = useQueryClient();
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, AuthTypes.IAuthRegisterRequest>(
    async (userRegister) => {
      const data = await request(
        Endpoints.auth.register.path,
        Endpoints.auth.register.method,
        Endpoints.auth.register.requiresAuth,
        storage,
        userRegister,
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.user]);
      }
    }
  );
}

export const useAuthLoginMutation = () => {
  const queryClient = useQueryClient();
  const storage = useStorage();
  return useMutation<AuthTypes.IAuthLoginResponse, Error, AuthTypes.IAuthLoginRequest>(
    async (userLogin) => {
      const data = await request(
        Endpoints.auth.login.path,
        Endpoints.auth.login.method,
        Endpoints.auth.login.requiresAuth,
        storage,
        userLogin);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.user]);
      }
    }
  );
}

export const useAuthVerifySubmitMutation = () => {
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, string>(
    async (code) => {
      const data = await request(
        Endpoints.auth.verify.submit.path(code),
        Endpoints.auth.verify.submit.method,
        Endpoints.auth.verify.submit.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const useAuthVerifyResendMutation = () => {
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, AuthTypes.IAuthVerifyCodeRequest>(
    async (userVerify) => {
      const data = await request(
        Endpoints.auth.verify.resend.path,
        Endpoints.auth.verify.resend.method,
        Endpoints.auth.verify.resend.requiresAuth,
        storage,
        userVerify
      );
      return data;
    }
  );
}

export const useAuthForgotPasswordRequestMutation = () => {
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, AuthTypes.IAuthForgotPasswordRequest>(
    async (userForgotPassword) => {
      const data = await request(
        Endpoints.auth.forgotPassword.request.path,
        Endpoints.auth.forgotPassword.request.method,
        Endpoints.auth.forgotPassword.request.requiresAuth,
        storage,
        userForgotPassword
      );
      return data;
    }
  );
}

export const useAuthForgotPasswordSubmitMutation = (code: string) => {
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, AuthTypes.IAuthForgotPasswordSubmitRequest>(
    async (userForgotPasswordSubmit) => {
      const data = await request(
        Endpoints.auth.forgotPassword.submit.path(code),
        Endpoints.auth.forgotPassword.submit.method,
        Endpoints.auth.forgotPassword.submit.requiresAuth,
        storage,
        userForgotPasswordSubmit
      );
      return data;
    }
  );
}


export const useAuthMeQuery = (config?: UseQueryOptions<UserTypes.IUser>) => {
  const storage = useStorage();
  return useQuery<UserTypes.IUser>(
    QueryKeys.user,
    async () => {
      const data = await request(
        Endpoints.auth.me.path,
        Endpoints.auth.me.method,
        Endpoints.auth.me.requiresAuth,
        storage,
      );
      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      ...config
    }
  );
}

export const useAuthChangePasswordMutation = () => {
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, AuthTypes.IAuthChangePasswordRequest>(
    async (userChangePassword) => {
      const data = await request(
        Endpoints.auth.changePassword.path,
        Endpoints.auth.changePassword.method,
        Endpoints.auth.changePassword.requiresAuth,
        storage,
        userChangePassword
      );
      return data;
    }
  );
}

export const useAuthUpdateProfileMutation = () => {
  const storage = useStorage();
  const queryClient = useQueryClient();
  return useMutation<ISuccessResponse, Error, UserTypes.IUserUpdateProfileRequest>(
    async (userUpdateProfile) => {
      const data = await request(
        Endpoints.auth.updateProfile.path,
        Endpoints.auth.updateProfile.method,
        Endpoints.auth.updateProfile.requiresAuth,
        storage,
        userUpdateProfile
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.user]);
      }
    }
  );
}

export const useAuthChangeEmailMutation = () => {
  const storage = useStorage();
  const queryClient = useQueryClient();
  return useMutation<ISuccessResponse, Error, AuthTypes.IAuthChangeEmailRequest>(
    async (userChangeEmail) => {
      const data = await request(
        Endpoints.auth.changeEmail.path,
        Endpoints.auth.changeEmail.method,
        Endpoints.auth.changeEmail.requiresAuth,
        storage,
        userChangeEmail
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.user]);
      }
    }
  );
}

export const useAuthSSOMutation = () => {
  const storage = useStorage();
  return useMutation<AuthTypes.IAuthSSOResponse, Error, AuthTypes.IAuthSSORequest>(
    async (ssoRequest) => {
      const data = await request(
        Endpoints.auth.sso.path,
        Endpoints.auth.sso.method,
        Endpoints.auth.sso.requiresAuth,
        storage,
        ssoRequest
      );
      return data;
    }
  );
}

export const useApiKeyCreateMutation = () => {
  const storage = useStorage();
  const queryClient = useQueryClient();
  return useMutation<KeysTypes.IApiKeyCreateResponse, Error, null>(
    async () => {
      const data = await request(
        Endpoints.api_key.create.path,
        Endpoints.api_key.create.method,
        Endpoints.api_key.create.requiresAuth,
        storage,
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.api_keys_list]);
      }
    }
  );
}

export const useApiKeyDeleteMutation = () => {
  const storage = useStorage();
  const queryClient = useQueryClient();
  return useMutation<ISuccessResponse, Error, number>(
    async (id) => {
      const data = await request(
        Endpoints.api_key.delete.path(id),
        Endpoints.api_key.delete.method,
        Endpoints.api_key.delete.requiresAuth,
        storage,
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.api_keys_list]);
      }
    }
  );
}

export const useApiKeyUpdateMutation = (id: number) => {
  const storage = useStorage();
  const queryClient = useQueryClient();
  return useMutation<ISuccessResponse, Error, KeysTypes.IApiKeyUpdateRequest>(
    async (apiKeyUpdate) => {
      const data = await request(
        Endpoints.api_key.update.path(id),
        Endpoints.api_key.update.method,
        Endpoints.api_key.update.requiresAuth,
        storage,
        apiKeyUpdate
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.api_keys_list]);
      }
    }
  );
}

export const useApiKeyListQuery = () => {
  const storage = useStorage();
  return useQuery<KeysTypes.IApiKey[], Error>(
    QueryKeys.api_keys_list,
    async () => {
      const data = await request(
        Endpoints.api_key.list.path,
        Endpoints.api_key.list.method,
        Endpoints.api_key.list.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const useApiKeyModelsListQuery = () => {
  const storage = useStorage();
  return useQuery<KeysTypes.IApiKeyModelResponse[], Error>(
    QueryKeys.api_key_models_list,
    async () => {
      const data = await request(
        Endpoints.api_key.models.list.path,
        Endpoints.api_key.models.list.method,
        Endpoints.api_key.models.list.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const useUsageListQuery = () => {
  const storage = useStorage();
  return useQuery<UsageTypes.IUsage[], Error>(
    QueryKeys.usage_list,
    async () => {
      const data = await request(
        Endpoints.usage.list.path,
        Endpoints.usage.list.method,
        Endpoints.usage.list.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const useUsageAggregatedQuery = () => {
  const storage = useStorage();
  return useQuery<UsageTypes.IUsageAggregated, Error>(
    QueryKeys.usage_aggregated,
    async () => {
      const data = await request(
        Endpoints.usage.list.path,
        Endpoints.usage.list.method,
        Endpoints.usage.list.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const usePaymentListQuery = () => {
  const storage = useStorage();
  return useQuery<PaymentTypes.IPayment[], Error>(
    QueryKeys.payment_list,
    async () => {
      const data = await request(
        Endpoints.payment.list.path,
        Endpoints.payment.list.method,
        Endpoints.payment.list.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const usePaymentCreateMutation = () => {
  const storage = useStorage();
  return useMutation<PaymentTypes.IPaymentCreateResponse, Error, PaymentTypes.IPaymentCreateRequest>(
    async (paymentCreate) => {
      const data = await request(
        Endpoints.payment.create.path,
        Endpoints.payment.create.method,
        Endpoints.payment.create.requiresAuth,
        storage,
        paymentCreate
      );
      return data;
    }
  );
}

export const useAffiliatesPromoCodesMeQuery = () => {
  const storage = useStorage();
  return useQuery<AffiliatesTypes.IPromoCodeUserResponse | null, Error>(
    QueryKeys.affiliates_promo_codes_me,
    async () => {
      const data = await request(
        Endpoints.affiliate.promo_code.me.path,
        Endpoints.affiliate.promo_code.me.method,
        Endpoints.affiliate.promo_code.me.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const useAffiliatesPromoCodesApplyMutation = () => {
  const queryClient = useQueryClient();
  const storage = useStorage();
  return useMutation<AffiliatesTypes.IPromoCodeUserResponse, Error, AffiliatesTypes.IPromoCodeApplyRequest>(
    async (promoCodeApply) => {
      const data = await request(
        Endpoints.affiliate.promo_code.apply.path(promoCodeApply.code),
        Endpoints.affiliate.promo_code.apply.method,
        Endpoints.affiliate.promo_code.apply.requiresAuth,
        storage,
      );
      return data;
    }, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.affiliates_promo_codes_me]);
      queryClient.invalidateQueries([QueryKeys.payment_list]);
      queryClient.invalidateQueries([QueryKeys.user]);
    }
  }
  );
}

export const useAffiliatesJoinMutation = () => {
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, AffiliatesTypes.IAffiliateJoinRequest>(
    async (affiliateJoin) => {
      const data = await request(
        Endpoints.affiliate.create.path,
        Endpoints.affiliate.create.method,
        Endpoints.affiliate.create.requiresAuth,
        storage,
        affiliateJoin
      );
      return data;
    }
  );
}

export const useAffiliatesMeQuery = () => {
  const storage = useStorage();
  return useQuery<AffiliatesTypes.IAffiliateInfoResponseSchema | null, Error>(
    QueryKeys.affiliates_me,
    async () => {
      const data = await request(
        Endpoints.affiliate.me.path,
        Endpoints.affiliate.me.method,
        Endpoints.affiliate.me.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const useAffiliatesPaymentsListQuery = () => {
  const storage = useStorage();
  return useQuery<AffiliatesTypes.IAffiliatePaymentResponseSchema[], Error>(
    QueryKeys.affiliates_payments_list,
    async () => {
      const data = await request(
        Endpoints.affiliate.payments.list.path,
        Endpoints.affiliate.payments.list.method,
        Endpoints.affiliate.payments.list.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const useAffiliatesPayoutsListQuery = () => {
  const storage = useStorage();
  return useQuery<AffiliatesTypes.IAffiliatePayoutResponseSchema[], Error>(
    QueryKeys.affiliates_payouts_list,
    async () => {
      const data = await request(
        Endpoints.affiliate.payouts.list.path,
        Endpoints.affiliate.payouts.list.method,
        Endpoints.affiliate.payouts.list.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const useAffiliatesPromoCodesCreateMutation = () => {
  const queryClient = useQueryClient();
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, AffiliatesTypes.IPromoCodeAffiliateCreateSchema>(
    async (promoCodeCreate) => {
      const data = await request(
        Endpoints.affiliate.promo_code.create.path,
        Endpoints.affiliate.promo_code.create.method,
        Endpoints.affiliate.promo_code.create.requiresAuth,
        storage,
        promoCodeCreate
      );
      return data;
    }, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.affiliates_promo_codes_list]);
    }
  }
  );
}

export const useAffiliatePromoCodesListQuery = () => {
  const storage = useStorage();
  return useQuery<AffiliatesTypes.IPromoCodeResponse[], Error>(
    QueryKeys.affiliates_promo_codes_list,
    async () => {
      const data = await request(
        Endpoints.affiliate.promo_code.list.path,
        Endpoints.affiliate.promo_code.list.method,
        Endpoints.affiliate.promo_code.list.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const usePaymentSubscriptionsListQuery = () => {
  const storage = useStorage();
  return useQuery<SubscriptionTypes.ISubscription[], Error>(
    QueryKeys.subscriptions,
    async () => {
      const data = await request(
        Endpoints.subscriptions.list.path,
        Endpoints.subscriptions.list.method,
        Endpoints.subscriptions.list.requiresAuth,
        storage,
      );
      return data;
    }
  );
}

export const usePaymentSubscriptionsActivateMutation = () => {
  const queryClient = useQueryClient();
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, string>(
    async (sku) => {
      const data = await request(
        Endpoints.subscriptions.activate.path(sku),
        Endpoints.subscriptions.activate.method,
        Endpoints.subscriptions.activate.requiresAuth,
        storage,
      );
      return data;
    }, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.subscriptions]);
      queryClient.invalidateQueries([QueryKeys.user]);
    }
  }
  );
}

export const usePaymentSubscriptionsDeactivateMutation = () => {
  const queryClient = useQueryClient();
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, string>(
    async (sku) => {
      const data = await request(
        Endpoints.subscriptions.deactivate.path(sku),
        Endpoints.subscriptions.deactivate.method,
        Endpoints.subscriptions.deactivate.requiresAuth,
        storage,
      );
      return data;
    }, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.subscriptions]);
    }
  }
  );
}

export const usePaymentSubscriptionsReactivateMutation = () => {
  const queryClient = useQueryClient();
  const storage = useStorage();
  return useMutation<ISuccessResponse, Error, string>(
    async (sku) => {
      const data = await request(
        Endpoints.subscriptions.reactivate.path(sku),
        Endpoints.subscriptions.reactivate.method,
        Endpoints.subscriptions.reactivate.requiresAuth,
        storage,
      );
      return data;
    }, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.subscriptions]);
    }
  }
  );
}