export enum KnownProducts {
  PRO = 'pro'
}

export interface IProduct {
  sku: KnownProducts;
  price: number;
}

export interface ISubscription {
  id: number;
  auto_renew: boolean;
  ended_at: string;
  product: IProduct;
}