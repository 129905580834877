import { useEffect, useState } from "react";
import { IApiKeyCreateResponse } from "../../types/keys";
import DialogModal from "../dialog";
import { useApiKeyCreateMutation } from "../../api/query";
import { useTranslation } from "react-i18next";
import KeyFieldWithCopy from "./KeyFieldWithCopy";
import { IRemoteError, RemoteErrorType, ServerFormError } from "../../types/errors";
import { useAuthContext } from "../../contexts/AuthContext";

interface Props {
    onClose: () => void;
}

export default function CreateKey({ onClose }: Props) {
    const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);
    const [newKey, setNewKey] = useState<IApiKeyCreateResponse | null>(null);
    const { mutate, error } = useApiKeyCreateMutation();
    const { user } = useAuthContext();

    const handleKeyCreate = () => {
        mutate(null, {
            onSuccess: (data) => {
                setNewKey(data);
            },
        });
    };

    useEffect(() => {
        if (error) {
            if (error instanceof ServerFormError) {
                const rootError = error.getErrorForField('body');
                if (rootError) {
                    setRemoteError(rootError);
                }
            } else {
                setRemoteError(ServerFormError.unknown());
            }
        }
    }, [error])

    const { t } = useTranslation();
    return (
        <>
            {
                newKey
                    ?
                    <DialogModal
                        title={t('keys.created.title')}
                        onClose={() => onClose()}
                    >
                        <div className="px-4 mt-4 mb-4 text-sm">
                            {t('keys.created.text')}
                        </div>
                        <div className="px-4 mt-4 mb-4 text-sm">
                            <KeyFieldWithCopy api_key={newKey.api_key} />
                        </div>
                        <div className="flex justify-end gap-2 px-4 mb-4">
                            <button
                                className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
                                onClick={() => onClose()}
                            >
                                {t('common.done')}
                            </button>
                        </div>
                    </DialogModal>
                    :
                    <DialogModal
                        title={t('keys.create.title')}
                        onClose={() => onClose()}
                    >
                        <div className="px-4 mt-4 mb-4 text-sm">
                            {t('keys.create.text')}
                        </div>
                        {
                            remoteError && (
                                <div
                                    className="mb-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700 text-sm mx-4"
                                    role="alert"
                                >
                                    {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`, { limit: user?.api_keys_limit ?? 0 })}
                                </div>
                            )
                        }
                        <div className="flex justify-end gap-2 px-4 mb-4">
                            <button
                                className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
                                onClick={() => {
                                    handleKeyCreate();
                                }}
                            >
                                {t('keys.create.positive_button')}
                            </button>
                            <button
                                className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-gray-600 hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
                                onClick={() => onClose()}
                            >
                                {t('common.cancel')}
                            </button>
                        </div>
                    </DialogModal>
            }
        </>
    )
}