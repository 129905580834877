import { useEffect, useState } from "react";
import DialogModal from "../dialog";
import { IRemoteError, RemoteErrorType, ServerFormError } from "../../types/errors";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { IPromoCodeAffiliateCreateSchema } from "../../types/affiliates";
import { useAffiliatesPromoCodesCreateMutation } from "../../api/query";
import InputMask from 'react-input-mask';

interface Props {
    onClose: () => void;
}

const CreatePromoCodeModal = ({ onClose }: Props) => {
    const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);
    const { t } = useTranslation();

    const today = new Date()
    today.setHours(0, 0, 0, 0);

    const inAYear = new Date();
    inAYear.setHours(0, 0, 0, 0);
    inAYear.setFullYear(inAYear.getFullYear() + 1);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<IPromoCodeAffiliateCreateSchema>({
        defaultValues: {
            from_date: today.toLocaleDateString('ru-RU').replace(/\//g, '.'),
            to_date: inAYear.toLocaleDateString('ru-RU').replace(/\//g, '.'),
        }
    });

    const fromDate = watch('from_date');
    const toDate = watch('to_date');

    const { mutate, error } = useAffiliatesPromoCodesCreateMutation();

    const onSubmit = (data: IPromoCodeAffiliateCreateSchema) => {
        data.from_date = data.from_date.split('.').reverse().join('-');
        data.to_date = data.to_date.split('.').reverse().join('-');
        setRemoteError(null);
        mutate(data, {
            onSuccess: () => {
                onClose();
            }
        });
    }

    useEffect(() => {
        if (error) {
            if (error instanceof ServerFormError) {
                const rootError = error.getErrorForField('body');
                if (rootError) {
                    setRemoteError(rootError);
                }
            } else {
                setRemoteError(ServerFormError.unknown());
            }
        }
    }, [error]);

    const parseDateFromString = (dateString: string) => {
        const [day, month, year] = dateString.split('.').map(Number);
        return new Date(year, month - 1, day);
    }

    const dateDiffInYears = (dateold: Date, datenew: Date) => {
        var ynew = datenew.getFullYear();
        var mnew = datenew.getMonth();
        var dnew = datenew.getDate();
        var yold = dateold.getFullYear();
        var mold = dateold.getMonth();
        var dold = dateold.getDate();
        var diff = ynew - yold;
        if (mold > mnew) diff--;
        else {
            if (mold == mnew) {
                if (dold > dnew) diff--;
            }
        }
        return diff;
    }

    const validateFromDate = () => {
        const fromDateDate = parseDateFromString(fromDate);

        if (fromDateDate < today)
            return t('affiliate.promo_codes.create.errors.from_date_after_today');

        if (fromDateDate > inAYear)
            return t('affiliate.promo_codes.create.errors.from_date_less_than_a_year');

        return validateDates()
    }

    const validateDates = () => {
        if (fromDate && toDate) {
            const fromDateDate = parseDateFromString(fromDate);
            const toDateDate = parseDateFromString(toDate);

            const diffInYears = dateDiffInYears(fromDateDate, toDateDate);

            if (fromDateDate > toDateDate) {
                return t('affiliate.promo_codes.create.errors.from_date_before_to_date');
            }

            if (diffInYears > 1) {
                return t('affiliate.promo_codes.create.errors.no_more_than_a_year');
            }
        }
        return true;
    }

    return (
        <DialogModal
            title={t('affiliate.promo_codes.create.title')}
            onClose={() => onClose()}
        >
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                {remoteError && (
                    <div
                        className="m-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                        role="alert"
                    >
                        {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`)}
                    </div>
                )}

                {/* code */}
                <div className="px-4 mt-4 mb-4">
                    <div className="relative">
                        <input
                            type="text"
                            id="code"
                            className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                            placeholder={" "}
                            aria-invalid={!!errors.code}
                            autoComplete="Off"
                            {...register('code', {
                                required: String(t('errors.local.required_field')),
                                minLength: {
                                    value: 6,
                                    message: String(t('errors.local.min_length', { count: 6 }))
                                },
                                maxLength: {
                                    value: 12,
                                    message: String(t('errors.local.max_length', { count: 12 }))
                                },
                            })}
                        />
                        <label
                            htmlFor="code"
                            className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                        >
                            {t('affiliate.promo_codes.create.fields.code')}
                        </label>
                    </div>
                    {errors.code && (
                        <span role="alert" className="mt-1 text-sm text-red-600">
                            {errors.code.message}
                        </span>
                    )}
                </div>

                {/* from date */}
                <div className="px-4 mt-4 mb-4">
                    <div className="relative">
                        <InputMask
                            id="from_date"
                            mask="99.99.9999"
                            placeholder="ДД.ММ.ГГГГ"
                            aria-invalid={!!errors.from_date}
                            autoComplete="Off"
                            className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                            defaultValue={today.toLocaleDateString('ru-RU').replace(/\//g, '.')}
                            {...register('from_date', {
                                required: String(t('errors.local.required_field')),
                                pattern: {
                                    value: /^\d{2}\.\d{2}\.\d{4}$/,
                                    message: String(t('errors.local.invalid_date_format')),
                                },
                                validate: validateFromDate,
                            })}
                        />
                        <label
                            htmlFor="from_date"
                            className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                        >
                            {t('affiliate.promo_codes.create.fields.from_date')}
                        </label>
                    </div>
                    {errors.from_date && (
                        <span role="alert" className="mt-1 text-sm text-red-600">
                            {errors.from_date.message}
                        </span>
                    )}
                </div>

                {/* to date */}
                <div className="px-4 mt-4 mb-4">
                    <div className="relative">
                        <InputMask
                            id="to_date"
                            mask="99.99.9999"
                            placeholder="ДД.ММ.ГГГГ"
                            aria-invalid={!!errors.to_date}
                            autoComplete="Off"
                            className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                            defaultValue={inAYear.toLocaleDateString('ru-RU').replace(/\//g, '.')}
                            {...register('to_date', {
                                required: String(t('errors.local.required_field')),
                                pattern: {
                                    value: /^\d{2}\.\d{2}\.\d{4}$/,
                                    message: String(t('errors.local.invalid_date_format')),
                                },
                                validate: validateDates,
                            })}
                        />
                        <label
                            htmlFor="to_date"
                            className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                        >
                            {t('affiliate.promo_codes.create.fields.to_date')}
                        </label>
                    </div>
                    {errors.to_date && (
                        <span role="alert" className="mt-1 text-sm text-red-600">
                            {errors.to_date.message}
                        </span>
                    )}
                </div>

                {/* multi-use */}
                <div className="px-4 mt-4 mb-4">
                    <div className="flex">
                        <input
                            type="checkbox"
                            id="multi_use"
                            className="peer h-4 w-4 border-gray-300 rounded-sm focus:ring-blue-600 text-blue-600 focus:outline-none mt-0.5"
                            {...register('multi_use')}
                        />
                        <label
                            htmlFor="multi_use"
                            className="ml-2 block text-sm text-gray-900"
                        >
                            {t('affiliate.promo_codes.create.fields.multi_use')}
                        </label>
                    </div>
                    {errors.multi_use && (
                        <span role="alert" className="mt-1 text-sm text-red-600">
                            {errors.multi_use.message}
                        </span>
                    )}
                </div>

                <div className="flex justify-end gap-2 px-4 mb-4">
                    <button
                        className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
                        type="submit"
                    >
                        {t('affiliate.promo_codes.create.button')}
                    </button>
                    <button
                        className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-gray-600 hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
                        onClick={() => onClose()}
                    >
                        {t('common.cancel')}
                    </button>

                </div>
            </form>
        </DialogModal>
    )
};

export default CreatePromoCodeModal;