import { useEffect } from 'react';
import { useHomeContext } from '../../contexts/HomeContext';
import { useTranslation } from 'react-i18next';
import Profile from './Profile';
import Email from './Email';
import Password from './Password';

export default function Account() {
    const {
        handleSetCurrentScreen,
        handleSetTitle,
    } = useHomeContext();

    useEffect(() => {
        handleSetCurrentScreen('account');
        handleSetTitle(t('account.title'));
    }, []);

    const { t } = useTranslation();
    return (
        <div className="flex flex-col overflow-y-auto h-full pb-8 text-gray-900">
            <div className="w-full mx-auto mt-6 md:mt-12 md:max-w-2xl lg:max-w-3xl px-4 flex flex-col">
                <h3 className="text-4xl mb-6 py-3">{t('account.title')}</h3>
                <div className="mb-6">
                    <Profile />
                </div>
                <div className="mb-6">
                    <Email />
                </div>
                <div className="mb-6">
                    <Password />
                </div>
            </div>
        </div>
    )
}