export enum ApiKeyPermission {
    BALANCE = "balance",
}

export interface IApiKey {
    id: number;
    key_part: string;
    last_used_at: string | null;
    created_at: string;
    limits: IApiKeyLimit[] | null;
    permissions: ApiKeyPermission[] | null;
}

export interface IApiKeyCreateResponse {
    api_key: string;
}

export interface IApiKeyLimit {
    model: string;
    limit: number;
}

export interface IApiKeyUpdateRequest {
    limits: IApiKeyLimit[] | null;
    permissions: ApiKeyPermission[] | null;
}

export interface IApiKeyModelResponse {
    id: number;
    provider: string;
    family: string;
    name: string;
}