import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AnalyticsEvents } from "../../types/site";
import { useEffect, useState } from "react";
import { useHomeContext } from "../../contexts/HomeContext";
import Spinner from "../ui/Spinner";

export default function Result() {
    const [complete, setComplete] = useState<boolean>(false);
    const { handleAnalyticsEvent } = useHomeContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const result = searchParams.get('result') === "success"

    const title = result ? t('billing.result.success.title') : t('billing.result.failure.title');
    const description = result ? t('billing.result.success.description') : t('billing.result.failure.description');

    useEffect(() => {
        if (result) {
            handleAnalyticsEvent(AnalyticsEvents.TOPUP);
        }
        setTimeout(() => {
            setComplete(true);
        }, 2000);
    }, []);

    return (
        <div className="flex min-h-screen flex-col items-center justify-center">
            <div className="w-96 px-4 sm:max-w-md md:px-0 overflow-hidden">
                <img src="/images/logo.svg" alt="ProxyAPI Logo" className="h-7 w-auto mb-12 mx-auto" />
                <h1 className="mb-10 text-4xl">{title}</h1>
                <div className="flex flex-col gap-4">
                    <p>{description}</p>
                    <button
                        type="button"
                        disabled={!complete}
                        className="w-full flex justify-center py-3 px-2.5 rounded-sm text-sm font-medium text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none disabled:bg-gray-400"
                        onClick={() => navigate('/billing')}
                    >
                        {t('common.ok')}
                        {!complete &&
                            <span className="ml-2">
                                <Spinner className="text-white w-5 h-5" />
                            </span>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}