import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthVerifySubmitMutation } from '../../api/query';
import { IRemoteError, ServerFormError, RemoteErrorType } from '../../types/errors';
import { useHomeContext } from '../../contexts/HomeContext';
import { AnalyticsEvents } from '../../types/site';

export default function Verify() {
    const [success, setSuccess] = useState(false);
    const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);
    const navigate = useNavigate();
    const { mutate, error } = useAuthVerifySubmitMutation()
    const { handleAnalyticsEvent } = useHomeContext();

    const { code } = useParams<{ code: string }>();

    useEffect(() => {
        if (code) {
            mutate(code, {
                onSuccess: () => {
                    setSuccess(true);
                    handleAnalyticsEvent(AnalyticsEvents.VERIFICATION);
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (error) {
            if (error instanceof ServerFormError) {
                const rootError = error.getErrorForField('body');
                if (rootError) {
                    setRemoteError(rootError);
                }
            } else {
                setRemoteError(ServerFormError.unknown());
            }
        }
    }, [error])

    const { t } = useTranslation();
    return (
        <div className="flex min-h-screen flex-col items-center justify-center">
            <div className="w-96 px-4 sm:max-w-md md:px-0 overflow-hidden">
                <img src="/images/logo.svg" alt="ProxyAPI Logo" className="h-7 w-auto mb-12 mx-auto" />
                <h1 className="mb-10 text-4xl">{t('auth.verify.title')}</h1>
                {remoteError && (
                    <div
                        className="mb-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                        role="alert"
                    >
                        {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`)}
                    </div>
                )}
                {success && (
                    <div
                        className="mb-4 rounded-sm border border-green-400 bg-green-100 px-4 py-3 text-green-700"
                        role="alert"
                    >
                        {t('auth.verify.success_message')}
                        <Link to="/login" className="block mt-6 font-medium underline text-xs">
                            {t('auth.verify.force_redirect')}
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}