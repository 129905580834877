import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaymentSubscriptionsActivateMutation, usePaymentSubscriptionsDeactivateMutation, usePaymentSubscriptionsReactivateMutation } from '../../api/query';
import { IRemoteError, RemoteErrorType, ServerFormError } from '../../types/errors';
import { ISubscription, KnownProducts } from '../../types/subscription';
import { formatPrice } from '../../utils/helpers';
import DialogModal from '../dialog';

interface Props {
  subscription: ISubscription | null;
  onClose: () => void;
}

const SubscribeModal = ({ subscription, onClose }: Props) => {
  const { t } = useTranslation();
  const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);

  const { mutate: activate, error: activateError } = usePaymentSubscriptionsActivateMutation();
  const { mutate: deactivate, error: deactivateError } = usePaymentSubscriptionsDeactivateMutation();
  const { mutate: reactivate, error: reactivateError } = usePaymentSubscriptionsReactivateMutation();

  let message = ""
  let buttonTitle = ""

  if (!subscription) {
    message = t('pro.manage.popup.activate.description', {
      price: formatPrice(1500, 0),
      period: t('pro.manage.placeholders.subscription_period'),
    });
    buttonTitle = t('pro.manage.popup.activate.button');
  } else {
    if (subscription.auto_renew) {
      message = t('pro.manage.popup.deactivate.description');
      buttonTitle = t('pro.manage.popup.deactivate.button');
    } else {
      message = t('pro.manage.popup.reactivate.description');
      buttonTitle = t('pro.manage.popup.reactivate.button');
    }
  }

  const submit = () => {
    if (!subscription) {
      activate(KnownProducts.PRO, {
        onSuccess: () => {
          onClose();
        }
      });
    } else {
      if (subscription.auto_renew) {
        deactivate(KnownProducts.PRO, {
          onSuccess: () => {
            onClose();
          }
        });
      } else {
        reactivate(KnownProducts.PRO, {
          onSuccess: () => {
            onClose();
          }
        });
      }
    }
  }

  useEffect(() => {
    const error = activateError || deactivateError || reactivateError;
    if (!error) {
      return;
    }

    if (error instanceof ServerFormError) {
      const rootError = error.getErrorForField('body');
      if (rootError) {
        setRemoteError(rootError);
      }
    } else {
      setRemoteError(ServerFormError.unknown());
    }
  }, [activateError, deactivateError, reactivateError]);

  return (
    <DialogModal
      title={t('pro.manage.popup.title')}
      onClose={() => onClose()}
    >
      <div className="px-4 mt-4 mb-4 text-sm">
        {message}
      </div>
      {
        remoteError && (
          <div
            className="mb-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700 text-sm mx-4"
            role="alert"
          >
            {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`)}
          </div>
        )
      }
      <div className="flex justify-end gap-2 px-4 mb-4">
        <button
          className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
          onClick={() => {
            submit();
          }}
        >
          {buttonTitle}
        </button>
        <button
          className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-gray-600 hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
          onClick={() => onClose()}
        >
          {t('common.cancel')}
        </button>
      </div>
    </DialogModal>
  );
}

export default SubscribeModal;