import { useEffect, useState } from "react";
import { ApiKeyPermission, IApiKey, IApiKeyLimit, IApiKeyModelResponse, IApiKeyUpdateRequest } from "../../types/keys";
import { IRemoteError, RemoteErrorType, ServerFormError } from "../../types/errors";
import { useApiKeyModelsListQuery, useApiKeyUpdateMutation } from "../../api/query";
import { useTranslation } from "react-i18next";
import Spinner from "../ui/Spinner";
import { CheckIcon } from "@heroicons/react/24/outline";
import { IUser } from "../../types/user";
import { userHasFeature } from "../../utils/helpers";
import { FeatureType } from "../../types/site";


interface Props {
    apiKey: IApiKey;
    user: IUser;
}

const EditLimit = (props: { model: string, limit: number | undefined, onLimitChange: (model: string, limit: number | undefined) => void }) => {
    const [limit, setLimit] = useState<number | undefined>(props.limit);

    const onLimitChange = (value: string) => {
        let newLimit: number | undefined = parseInt(value, undefined);
        if (newLimit < 0) return;
        if (newLimit === 0) {
            newLimit = undefined;
        }

        setLimit(newLimit);
        props.onLimitChange(props.model, newLimit);
    }

    const { t } = useTranslation();

    return (
        <div className="flex gap-2 items-center">
            <input
                readOnly
                type="checkbox"
                id={`model-${props.model}`}
                className="peer h-4 w-4 border-gray-300 rounded-sm focus:ring-blue-600 text-blue-600 focus:outline-none mt-0.5"
                checked={limit !== undefined}
            />
            <label
                className="w-1/3"
                htmlFor={`limit-${props.model}`}>
                {props.model}
            </label>
            <input
                type="number"
                step={100}
                id={`limit-${props.model}`}
                className="peer block px-2.5 py-2 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none text-sm"
                value={limit || ''}
                onChange={(e) => onLimitChange(e.target.value)}
                placeholder={t('keys.edit.limits.fields.placeholder')}
            />
        </div>
    );
}

const EditLimits = ({ limits, setLimits }: { limits: IApiKeyLimit[], setLimits: (limits: IApiKeyLimit[]) => void }) => {
    const [models, setModels] = useState<string[]>([]);
    const { data: modelsData } = useApiKeyModelsListQuery();

    useEffect(() => {
        if (modelsData) {
            const modelsNames: Set<string> = new Set();
            modelsData.forEach((model: IApiKeyModelResponse) => {
                modelsNames.add(model.name);
                modelsNames.add(model.family);
            });
            const modelsNamesArray = Array.from(modelsNames);
            modelsNamesArray.sort();
            setModels(modelsNamesArray);
        }
    }, [modelsData]);

    const onLimitChange = (model: string, limit: number | undefined) => {
        const newLimits = [...limits];
        const index = newLimits.findIndex(l => l.model === model);
        if (index === -1 && limit !== undefined) {
            newLimits.push({ model, limit });
        } else if (index !== -1 && limit === undefined) {
            newLimits.splice(index, 1);
        } else if (index !== -1 && limit !== undefined) {
            newLimits[index] = { model, limit };
        }
        setLimits(newLimits);
    }

    const { t } = useTranslation();

    return (
        <>
            <h4 className="text-xl mt-3">{t('keys.edit.limits.title')}</h4>
            <div className="flex flex-col gap-3 mt-3">
                {models.map((model, index) => (
                    <EditLimit model={model} limit={limits.find(l => l.model === model)?.limit} onLimitChange={onLimitChange} key={index} />
                ))}
            </div>
        </>
    )

}

const EditPermissions = ({ permissions, setPermissions }: { permissions: ApiKeyPermission[], setPermissions: (permissions: ApiKeyPermission[]) => void }) => {
    const { t } = useTranslation();

    const onPermissionChange = (permission: ApiKeyPermission) => {
        const newPermissions = [...permissions];
        const index = newPermissions.indexOf(permission);
        if (index === -1) {
            newPermissions.push(permission);
        } else {
            newPermissions.splice(index, 1);
        }
        setPermissions(newPermissions);
    }

    return (
        <>
            <h4 className="text-xl">{t('keys.edit.permissions.title')}</h4>
            <p className="text-gray-500 text-xs">{t('keys.edit.permissions.hint')}</p>
            <div className="flex flex-col gap-3 mt-3">
                <div className="flex gap-2 items-center">
                    <input
                        type="checkbox"
                        id="permission-balance"
                        className="peer h-4 w-4 border-gray-300 rounded-sm focus:ring-blue-600 text-blue-600 focus:outline-none mt-0.5"
                        checked={permissions.includes(ApiKeyPermission.BALANCE)}
                        onChange={() => onPermissionChange(ApiKeyPermission.BALANCE)}
                    />
                    <label
                        htmlFor="permission-balance">
                        {t('keys.edit.permissions.features.balance')}
                    </label>
                </div>
            </div>
        </>
    )
}

const EditKey = ({ apiKey, user }: Props) => {
    const [limits, setLimits] = useState<IApiKeyLimit[]>(apiKey.limits || []);
    const [permissions, setPermissions] = useState<ApiKeyPermission[]>(apiKey.permissions || []);
    const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);
    const [success, setSuccess] = useState(false);
    const { mutate, error, isLoading } = useApiKeyUpdateMutation(apiKey.id);
    const { t } = useTranslation();

    useEffect(() => {
        if (error) {
            if (error instanceof ServerFormError) {
                const rootError = error.getErrorForField('body');
                if (rootError) {
                    setRemoteError(rootError);
                }
                ['limits'].forEach(field => {
                    const fieldError = error.getErrorForField(field);
                    if (fieldError) {
                        // @ts-ignore
                        setError(field, {
                            type: 'manual',
                            message: fieldError.msg
                        });
                    }
                });
            } else {
                setRemoteError(ServerFormError.unknown());
            }
        }
    }, [error])

    const onSubmit = () => {
        setRemoteError(null);
        setSuccess(false);
        const data: IApiKeyUpdateRequest = {
            limits: limits.length > 0 ? limits : null,
            permissions: permissions.length > 0 ? permissions : null
        };
        mutate(data, {
            onSuccess: (data) => {
                if (data?.success === true) {
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 3000);
                }
            }
        });
        return false;
    };

    const userHasApiKeyWithLimitsFeature = user ? userHasFeature(user, FeatureType.API_KEY_WITH_LIMITS) : false;

    return (
        <div className="bg-gray-100 p-4">
            {
                remoteError && (
                    <div
                        className="mb-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                        role="alert"
                    >
                        {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`)}
                    </div>
                )
            }
            <form onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
            }}>
                <EditPermissions permissions={permissions} setPermissions={setPermissions} />
                {
                    userHasApiKeyWithLimitsFeature && (
                        <EditLimits limits={limits} setLimits={setLimits} />
                    )
                }
                <div className="flex justify-end mt-3">
                    <button
                        type="submit"
                        className="flex gap-1 justify-center py-2 px-2.5 rounded-sm text-xs font-medium text-white transform transition-colors duration-200 bg-gray-600 hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
                        disabled={isLoading || success}
                    >
                        {t('keys.edit.button')}
                        {
                            isLoading && (
                                <Spinner className="text-white w-4 h-4" />
                            )
                        }
                        {
                            success && (
                                <CheckIcon className="w-4 h-4" />
                            )
                        }
                    </button>
                </div>
                <p className="text-xs text-gray-500 mt-2 text-end">{t('keys.edit.hint')}</p>
            </form>
        </div>
    )
};

export default EditKey;