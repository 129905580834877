export interface StorageWrapper {
    setItem(key: string, value: string): void;
    getItem(key: string): string | null;
    removeItem(key: string): void;
}

class LocalStorageWrapper implements StorageWrapper {
    setItem(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    getItem(key: string): string | null {
        return localStorage.getItem(key);
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }
}

const useStorage = (): StorageWrapper => {
    const storage = new LocalStorageWrapper();
    return storage;
};

export default useStorage;