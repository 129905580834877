import { useEffect, useState } from "react";
import { IAffiliatePaymentResponseSchema } from "../../types/affiliates";
import { useAffiliatesPaymentsListQuery } from "../../api/query";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../utils/helpers";


const Payments = () => {
    const [payments, setPayments] = useState<IAffiliatePaymentResponseSchema[]>([]);
    const { data: paymentsData } = useAffiliatesPaymentsListQuery();

    useEffect(() => {
        if (paymentsData) {
            setPayments(paymentsData);
        }
    }, [paymentsData]);

    const { t } = useTranslation();

    return (
        <div className="flex flex-col mb-6">
            <h3 className="text-3xl py-3">{t('affiliate.payments.title')}</h3>
            <div className="overflow-x-auto md:overflow-x-hidden">
                <table className="w-full table-auto text-sm whitespace-nowrap">
                    <thead className="text-left">
                        <tr className="border-b border-gray-200">
                            <th className="p-2 font-semibold">{t('affiliate.payments.head.date')}</th>
                            <th className="p-2 font-semibold">{t('affiliate.payments.head.amount')}</th>
                            <th className="p-2 font-semibold">{t('affiliate.payments.head.status')}</th>
                            <th className="p-2 font-semibold">{t('affiliate.payments.head.payout')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-700 text-sm">
                        {payments.map((payment, index) => (
                            <tr key={index} className="border-b border-gray-200">
                                <td className="p-2">{new Date(payment.created_at).toLocaleDateString()}</td>
                                <td className="p-2">{formatPrice(payment.payout_amount, 2)}</td>
                                <td className="p-2">{t(`affiliate.payments.status.${payment.payment_status}`)}</td>
                                <td className="p-2">{payment.payout ? new Date(payment.payout.created_at).toLocaleDateString() : "-"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default Payments;