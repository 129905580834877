import { createContext, useEffect, useContext } from 'react';
import { ThemeMode } from '../types/site';

export const ThemeContext = createContext<ThemeMode | undefined>(undefined);

interface ThemeProviderProps {
    children: React.ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const getSystemTheme = () => {
        const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
        if (userMedia.matches) {
            return ThemeMode.DARK;
        }

        return ThemeMode.LIGHT;
    }

    const theme = getSystemTheme();

    useEffect(() => {
        const root = window.document.documentElement;
        const isDark = theme === ThemeMode.DARK;
        root.classList.remove(isDark ? ThemeMode.LIGHT : ThemeMode.DARK);
        root.classList.add(theme);
    }, []);

    return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};

const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (context === undefined) {
        throw new Error('useThemeContext should be used inside ThemeProvider');
    }
    return context;
};

export { useThemeContext, ThemeProvider };