export enum ThemeMode {
    DARK = 'dark',
    LIGHT = 'light'
}

export interface ISuccessResponse {
    success: boolean;
}

export enum AnalyticsEvents {
    REGISTRATION = 'registration',
    VERIFICATION = 'verification',
    TOPUP = 'topup',
}

export enum FeatureType {
    API_KEY_WITH_LIMITS = "api_key_with_limits"
}