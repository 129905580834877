import { useEffect, useState } from "react";
import { IAffiliatePayoutResponseSchema } from "../../types/affiliates";
import { useAffiliatesPayoutsListQuery } from "../../api/query";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../utils/helpers";


const Payouts = () => {
    const [payouts, setPayouts] = useState<IAffiliatePayoutResponseSchema[]>([]);
    const { data: payoutsData } = useAffiliatesPayoutsListQuery();

    useEffect(() => {
        if (payoutsData) {
            setPayouts(payoutsData);
        }
    }, [payoutsData]);

    const { t } = useTranslation();

    return (
        <div className="flex flex-col mb-6">
            <h3 className="text-3xl py-3">{t('affiliate.payouts.title')}</h3>
            <div className="overflow-x-auto md:overflow-x-hidden">
                <table className="w-full table-auto text-sm whitespace-nowrap">
                    <thead className="text-left">
                        <tr className="border-b border-gray-200">
                            <th className="p-2 font-semibold">{t('affiliate.payouts.head.date')}</th>
                            <th className="p-2 font-semibold">{t('affiliate.payouts.head.amount')}</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-700 text-sm">
                        {payouts.map((payout, index) => (
                            <tr key={index} className="border-b border-gray-200">
                                <td className="p-2">{new Date(payout.created_at).toLocaleDateString()}</td>
                                <td className="p-2">{formatPrice(payout.amount, 2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default Payouts;