import { useAuthChangeEmailMutation, useAuthChangePasswordMutation } from "../../api/query";
import { useEffect, useState } from "react";
import { IRemoteError, RemoteErrorType, ServerFormError } from "../../types/errors";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IAuthChangeEmailRequest, IAuthChangePasswordRequest } from "../../types/auth";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function Password() {
    const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);
    const [success, setSuccess] = useState(false);

    const { mutate, error } = useAuthChangePasswordMutation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        watch,
    } = useForm<IAuthChangePasswordRequest>();

    const onSubmit = (data: IAuthChangePasswordRequest) => {
        setRemoteError(null);
        setSuccess(false);
        mutate(data, {
            onSuccess: () => {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 1000);
            }
        });
    };

    const new_password = watch('new_password');

    useEffect(() => {
        if (error) {
            if (error instanceof ServerFormError) {
                const rootError = error.getErrorForField('body');
                if (rootError) {
                    setRemoteError(rootError);
                }
                ['old_password', 'new_password'].forEach(field => {
                    const fieldError = error.getErrorForField(field);
                    if (fieldError) {
                        // @ts-ignore
                        setError(field, {
                            type: 'manual',
                            message: fieldError.msg
                        });
                    }
                });
            } else {
                setRemoteError(ServerFormError.unknown());
            }
        }
    }, [error])

    const passwordValidator = (value: string) => {
        if (!/\d/.test(value))
            return t('auth.register.password_invalid')
        if (!/[^\d]/.test(value))
            return t('auth.register.password_invalid')
        return true;
    };

    const { t } = useTranslation();
    return (
        <>
            <h4 className="mb-6 text-2xl">{t('account.password.title')}</h4>
            {
                remoteError && (
                    <div
                        className="mb-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                        role="alert"
                    >
                        {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`)}
                    </div>
                )
            }
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="w-full mb-3">
                    <div className="relative">
                        <input
                            type="password"
                            id="old_password"
                            className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                            placeholder={" "}
                            aria-invalid={!!errors.old_password}
                            autoComplete="current-password"
                            {...register('old_password', {
                                required: String(t('errors.local.required_field'))
                            })}
                        />
                        <label
                            htmlFor="old_password"
                            className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                        >
                            {t('account.password.old_password_label')}
                        </label>
                    </div>
                    {errors.old_password && (
                        <span role="alert" className="mt-1 text-sm text-red-600">
                            {errors.old_password.message}
                        </span>
                    )}
                </div>
                <div className="flex flex-col md:flex-row justify-center gap-3 mb-6">
                    <div className="w-full md:flex-1">
                        <div className="relative">
                            <input
                                type="password"
                                id="new_password"
                                className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                                placeholder={" "}
                                aria-invalid={!!errors.new_password}
                                autoComplete="new-password"
                                minLength={8}
                                maxLength={150}
                                {...register('new_password', {
                                    required: String(t('errors.local.required_field')),
                                    minLength: {
                                        value: 8,
                                        message: t('errors.local.min_length', { count: 8 })
                                    },
                                    maxLength: {
                                        value: 150,
                                        message: t('errors.local.max_length', { count: 150 })
                                    },
                                    validate: passwordValidator
                                })}
                            />
                            <label
                                htmlFor="new_password"
                                className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                            >
                                {t('account.password.new_password_label')}
                            </label>
                        </div>
                        {errors.new_password && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.new_password.message}
                            </span>
                        )}
                    </div>
                    <div className="w-full md:flex-1">
                        <div className="relative">
                            <input
                                type="password"
                                id="new_password_confirm"
                                className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                                placeholder={" "}
                                aria-invalid={!!errors.new_password_confirm}
                                autoComplete="new-password"
                                {...register('new_password_confirm', {
                                    required: String(t('errors.local.required_field')),
                                    validate: (value) => value === new_password || String(t('account.password.password_confirmation_invalid'))
                                })}
                            />
                            <label
                                htmlFor="new_password_confirm"
                                className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                            >
                                {t('account.password.password_confirmation_label')}
                            </label>
                        </div>
                        {errors.new_password_confirm && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.new_password_confirm.message}
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex gap-2 justify-start items-center">
                    <button
                        type="submit"
                        className="justify-center py-3 px-2.5 rounded-sm text-sm font-medium text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
                    >
                        {t('account.password.submit_button')}
                    </button>
                    {
                        success && (
                            <div className="text-green-700">
                                <CheckIcon className="w-5 h-5" />
                            </div>
                        )
                    }
                </div>
            </form>
        </>
    )
}