import { FeatureType } from "../types/site";
import { IUser } from "../types/user";

export function formatPrice(price: number, decimalDigits: number = 6, currency: string = process.env.REACT_APP_CURRENCY_SYMBOL ?? ""): string {
    const formatter = new Intl.NumberFormat("ru-RU", {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: decimalDigits,
    });

    return formatter.format(price);
}

export function userHasFeature(user: IUser, feature: FeatureType): boolean {
    return user.features_enabled.includes(feature);
}