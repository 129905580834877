import { useAuthContext } from "../../contexts/AuthContext";
import { useAuthUpdateProfileMutation } from "../../api/query";
import { useEffect, useState } from "react";
import { IRemoteError, RemoteErrorType, ServerFormError } from "../../types/errors";
import { IUserUpdateProfileRequest } from "../../types/user";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function Profile() {
    const [success, setSuccess] = useState(false);
    const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);

    const { user } = useAuthContext();
    const { mutate, error } = useAuthUpdateProfileMutation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError
    } = useForm<IUserUpdateProfileRequest>();

    const onSubmit = (data: IUserUpdateProfileRequest) => {
        setRemoteError(null);
        mutate(data, {
            onSuccess: () => {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 1000);
            }
        });
    };

    useEffect(() => {
        if (error) {
            if (error instanceof ServerFormError) {
                const rootError = error.getErrorForField('body');
                if (rootError) {
                    setRemoteError(rootError);
                }
                ['first_name', 'last_name'].forEach(field => {
                    const fieldError = error.getErrorForField(field);
                    if (fieldError) {
                        // @ts-ignore
                        setError(field, {
                            type: 'manual',
                            message: fieldError.msg
                        });
                    }
                });
            } else {
                setRemoteError(ServerFormError.unknown());
            }
        }
    }, [error])

    const { t } = useTranslation();

    return (
        <>
            <h4 className="mb-6 text-2xl">{t('account.profile.title')}</h4>
            {
                remoteError && (
                    <div
                        className="mb-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                        role="alert"
                    >
                        {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`)}
                    </div>
                )
            }
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="flex flex-col md:flex-row justify-center gap-3 mb-6">
                    <div className="w-full md:flex-1">
                        <div className="relative">
                            <input
                                type="first_name"
                                id="first_name"
                                className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                                placeholder={" "}
                                aria-invalid={!!errors.first_name}
                                autoComplete="given-name"
                                defaultValue={user?.first_name}
                                {...register('first_name', {
                                    required: String(t('errors.local.required_field')),
                                    maxLength: {
                                        value: 50,
                                        message: t('errors.local.max_length', { count: 50 })
                                    },
                                })}
                            />
                            <label
                                htmlFor="first_name"
                                className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                            >
                                {t('account.profile.first_name_label')}
                            </label>
                        </div>
                        {errors.first_name && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.first_name.message}
                            </span>
                        )}
                    </div>
                    <div className="w-full md:flex-1">
                        <div className="relative">
                            <input
                                type="last_name"
                                id="last_name"
                                className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                                placeholder={" "}
                                aria-invalid={!!errors.last_name}
                                autoComplete="family-name"
                                defaultValue={user?.last_name}
                                {...register('last_name', {
                                    required: String(t('errors.local.required_field')),
                                    maxLength: {
                                        value: 50,
                                        message: t('errors.local.max_length', { count: 50 })
                                    }
                                })}
                            />
                            <label
                                htmlFor="last_name"
                                className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                            >
                                {t('account.profile.last_name_label')}
                            </label>
                        </div>
                        {errors.last_name && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.last_name.message}
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex gap-2 justify-start items-center">
                    <button
                        type="submit"
                        className="justify-center py-3 px-2.5 rounded-sm text-sm font-medium text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
                    >
                        {t('account.profile.submit_button')}

                    </button>
                    {
                        success && (
                            <div className="text-green-700">
                                <CheckIcon className="w-5 h-5" />
                            </div>
                        )
                    }
                </div>
            </form>
        </>
    )
}