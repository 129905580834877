import React, { useEffect, useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface ModalProps {
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

const DialogModal: React.FC<ModalProps> = ({ onClose, title, children }) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleMouseDown = (e: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
                window.addEventListener('mouseup', handleMouseUp);
            }
        };

        const handleMouseUp = (e: MouseEvent) => {
            window.removeEventListener('mouseup', handleMouseUp);
            onClose();
        };

        window.addEventListener('mousedown', handleMouseDown);

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
        };
    }, [onClose]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="fixed inset-0 z-10 overflow-hidden">
                <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <div
                        className="hidden sm:inline-block sm:h-screen sm:align-middle"
                        aria-hidden="true"
                    />
                    <div
                        ref={modalRef}
                        className="inline-block max-h-[400px] transform overflow-y-auto rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-h-[600px] w-full sm:max-w-lg sm:align-middle"
                        role="dialog"
                    >
                        <div className="flex justify-between items-center p-4">
                            <h2 className="text-lg font-medium text-black">{title}</h2>
                            <button
                                className="text-gray-900 hover:text-gray-500 transition-colors duration-200"
                                onClick={onClose}
                            >
                                <XMarkIcon className="w-5 h-5" />
                            </button>
                        </div>
                        <div className="border-b border-gray-200" />
                        {children}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default DialogModal;
