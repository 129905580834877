import {
    useState,
    useEffect,
    ReactNode,
    createContext,
    useContext
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IUser } from '../types/user';
import { useAuthMeQuery } from '../api/query';
import useStorage from '../utils/storage';
import { UnauthorizedError } from '../types/errors';

interface IAuthContextType {
    isAuthenticated: boolean;
    isLoading: boolean;
    user: IUser | undefined;
    login: (token: string, refreshToken: string, user: IUser) => void;
    logout: () => void;
}

const AuthContext = createContext<IAuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<IUser | undefined>(undefined);

    const userQuery = useAuthMeQuery({ enabled: !!token });
    const navigate = useNavigate();
    const storage = useStorage();
    const location = useLocation();

    const excludeRoutes = [
        '/login',
        '/register',
        /^\/login\/[^/]+\/callback$/, // Regular expression pattern
    ];
    const loginRoute = '/login';

    const login = (token: string, refreshToken: string, user?: IUser | undefined) => {
        setUser(user);
        handleSetToken(token);
        handleSetRefreshToken(refreshToken);
    };

    const logout = () => {
        setUser(undefined);
        handleSetToken(undefined);
        handleSetRefreshToken(undefined);
    };

    const handleSetToken = (token: string | undefined) => {
        setToken(token);
        setIsAuthenticated(!!token);
        if (token) {
            storage.setItem('token', token);
        } else {
            storage.removeItem('token');
        }
    };

    const handleSetRefreshToken = (refreshToken: string | undefined) => {
        if (refreshToken) {
            storage.setItem('refreshToken', refreshToken);
        } else {
            storage.removeItem('refreshToken');
        }
    };

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
            setIsLoading(false);
        } else if (userQuery.isError) {
            if (userQuery.error instanceof UnauthorizedError) {
                logout();
                navigate(loginRoute);
            }
            setIsLoading(false);
        }

        if (!token) {
            const tokenStored = storage.getItem('token');
            if (tokenStored) {
                handleSetToken(tokenStored);
            } else {
                if (!excludeRoutes.some((route) => {
                    if (typeof route === 'string') {
                        return location.pathname === route;
                    } else if (route instanceof RegExp) {
                        return route.test(location.pathname);
                    }
                })) {
                    logout();
                    navigate(loginRoute);
                }
                setIsLoading(false);
            }
        }
    }, [token, isAuthenticated, userQuery.data, userQuery.isError]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuthContext = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuthContext should be used inside AuthProvider');
    }

    return context;
};

export { useAuthContext, AuthProvider };