import { useNavigate } from 'react-router-dom';
import { ArrowLeftOnRectangleIcon, ChartBarIcon, HomeIcon, KeyIcon, LockClosedIcon, RocketLaunchIcon, WalletIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useHomeContext } from '../../contexts/HomeContext';
import { useAuthContext } from '../../contexts/AuthContext';
import Link from './FancyLink';

interface Props {
    setIsOpen: (isOpen: boolean) => void;
}

export default function TopLinks({ setIsOpen }: Props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state: { currentScreen } } = useHomeContext();
    const { user, logout: handleLogout } = useAuthContext();

    const handleNavigation = (path: string) => {
        setIsOpen(false);
        navigate(path);
    }

    const logout = () => {
        setIsOpen(false);
        handleLogout();
    }

    return (
        <>
            <Link
                icon={<HomeIcon className="w-5 h-5" />}
                label={t('menu.home')}
                selected={currentScreen === 'home'}
                onClick={() => handleNavigation('/')}
            />
            <Link
                icon={<LockClosedIcon className="w-5 h-5" />}
                label={t('menu.account')}
                selected={currentScreen === 'account'}
                onClick={() => handleNavigation('/account')}
            />
            {user && user.is_affiliate && (
                <Link
                    icon={<ChartBarIcon className="w-5 h-5" />}
                    label={t('menu.affiliate_program')}
                    selected={currentScreen === 'partner'}
                    onClick={() => handleNavigation('/partner')}
                />
            )}
            <Link
                icon={<KeyIcon className="w-5 h-5" />}
                label={t('menu.api_keys')}
                selected={currentScreen === 'keys'}
                onClick={() => handleNavigation('/keys')}
            />
            <Link
                icon={<RocketLaunchIcon className="w-5 h-5" />}
                label={t('menu.pro')}
                selected={currentScreen === 'pro'}
                onClick={() => handleNavigation('/pro')}
            />
            <Link
                icon={<WalletIcon className="w-5 h-5" />}
                label={t('menu.billing')}
                selected={currentScreen === 'billing'}
                onClick={() => handleNavigation('/billing')}
            />
            <Link
                icon={<ArrowLeftOnRectangleIcon className="w-5 h-5" />}
                label={t('menu.logout')}
                selected={false}
                onClick={logout}
            />

        </>
    )
}