import { useEffect, useState } from "react";
import { IAffiliateInfoResponseSchema } from "../../types/affiliates";
import { useAffiliatesMeQuery } from "../../api/query";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../utils/helpers";


const Balance = () => {
    const [affiliate, setAffiliate] = useState<IAffiliateInfoResponseSchema | null>(null);
    const { data: affiliateData } = useAffiliatesMeQuery();

    useEffect(() => {
        if (affiliateData) {
            setAffiliate(affiliateData);
        }
    }, [affiliateData]);

    const { t } = useTranslation();

    return affiliate ? (
        <div className="rounded rounded-md border border-gray-200 py-2 px-4 flex flex-col md:flex-row mb-6 items-start md:items-center justify-between">
            <div className="flex flex-col items-start">
                <h3 className="text-4xl pb-2">{t('affiliate.balance.pending')}</h3>
                <div className="text-2xl text-blue-600 font-normal">{formatPrice(affiliate.pending_payout, 2)}</div>
            </div>
            <div className="flex flex-col items-start md:items-end">
                <h4 className="text-2xl pb-1">{t('affiliate.balance.paid')}</h4>
                <div className="text-xl text-blue-600 font-normal">{formatPrice(affiliate.paid_payout, 2)}</div>
            </div>
        </div>
    ) : null;
};

export default Balance;