import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAuthSSOMutation } from "../../api/query";
import Spinner from "../ui/Spinner";


const SSO = () => {
  const [params] = useSearchParams();
  const payload = params.get('sso');
  const sig = params.get('sig');

  const { mutate } = useAuthSSOMutation();
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();

  const defaultError = t('auth.sso.error');

  useEffect(() => {
    if (payload && sig) {
      mutate({ payload, sig }, {
        onSuccess: (data) => {
          window.location.href = data.url;
        },
        onError: () => {
          setError(defaultError);
        }
      });
    } else {
      setError(defaultError);
    }
  }, [payload, sig]);

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <div className="px-4 sm:max-w-md md:px-0 overflow-hidden">
        <h1 className="mb-10 text-4xl">{t('auth.sso.title')}</h1>
        {
          !error && (
            <div className="text-center">
              <Spinner className="text-blue-600 w-10 h-10 mx-auto" />
            </div>
          )
        }
        {
          error && (
            <div
              className="mb-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              {error}
            </div>
          )
        }
      </div>
    </div>
  )
}

export default SSO;