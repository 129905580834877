
import { Fragment, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHomeContext } from "../../contexts/HomeContext";
import { useApiKeyListQuery, useApiKeyDeleteMutation } from "../../api/query";
import { IApiKey } from "../../types/keys";
import { PencilSquareIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import DialogModal from "../dialog";
import CreateKey from "./CreateKey";
import { useAuthContext } from "../../contexts/AuthContext";
import EditKey from "./EditKey";

export default function Keys() {
    const { user } = useAuthContext();
    const {
        handleSetCurrentScreen,
        handleSetTitle,
    } = useHomeContext();
    const { data } = useApiKeyListQuery();
    const { mutate: deleteMutation } = useApiKeyDeleteMutation();

    const [keys, setKeys] = useState<IApiKey[]>([]);
    const [deleteKey, setDeleteKey] = useState<IApiKey | null>(null);
    const [createKey, setCreateKey] = useState<boolean>(false);
    const [editKey, setEditKey] = useState<IApiKey | null>(null);

    useEffect(() => {
        handleSetCurrentScreen('keys');
        handleSetTitle(t('keys.title'));
    }, []);

    useEffect(() => {
        if (data) {
            setKeys(data);
            setDeleteKey(null);
        }
    }, [data]);

    const handleKeyDelete = (key: IApiKey) => {
        deleteMutation(key.id, {
            onSuccess: () => {
                setKeys(keys.filter(k => k.id !== key.id));
            },
        });
    };

    const docsUrl = `${process.env.REACT_APP_BRAND_URL}/docs`;

    const { t } = useTranslation();
    return (
        <>
            <div className="flex flex-col overflow-y-auto h-full pb-8 text-gray-900">
                <div className="w-full mx-auto mt-6 md:mt-12 md:max-w-2xl lg:max-w-3xl px-4 flex flex-col">
                    <h3 className="text-4xl mb-6 py-3">{t('keys.title')}</h3>
                    <p className="mb-6 text-gray-700 font-light">
                        {t('keys.disclaimer_1')}
                    </p>
                    <p className="mb-10 text-gray-700 font-light">
                        {t('keys.disclaimer_2')}
                    </p>

                    <table className="w-full table-auto text-sm mb-2">
                        <thead className="text-left">
                            <tr className="border-b border-gray-200">
                                <th className="py-2 font-semibold">{t('keys.list.head.key')}</th>
                                <th className="py-2 font-semibold">{t('keys.list.head.created_at')}</th>
                                <th className="py-2 font-semibold">{t('keys.list.head.last_used_at')}</th>
                                <th className="py-2 font-semibold">{' '}</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700 text-sm">
                            {keys.map((key, index) => (
                                <Fragment key={index}>
                                    <tr className="border-b border-gray-200">
                                        <td className="py-2">{key.key_part}</td>
                                        <td className="py-2">{new Date(key.created_at).toLocaleDateString()}</td>
                                        <td className="py-2">{key.last_used_at ? new Date(`${key.last_used_at}.000Z`).toLocaleString() : t("common.never")}</td>
                                        <td className="py-2 flex gap-2">
                                            <button className="text-gray-900 hover:text-gray-500"
                                                onClick={() => {
                                                    setEditKey(editKey === key ? null : key);
                                                }}
                                            >
                                                <PencilSquareIcon className="w-4 h-4" />
                                            </button>
                                            <button className="text-gray-900 hover:text-gray-500"
                                                onClick={() => {
                                                    setDeleteKey(key);
                                                }}
                                            >
                                                <TrashIcon className="w-4 h-4" />
                                            </button>
                                        </td>
                                    </tr>
                                    {
                                        user && editKey && editKey.id === key.id && (
                                            <tr className="border-b border-gray-200">
                                                <td colSpan={4}>
                                                    <EditKey apiKey={editKey} user={user} />
                                                </td>
                                            </tr>
                                        )
                                    }
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        <button
                            className="flex gap-2 justify-center py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
                            onClick={() => setCreateKey(true)}
                        >
                            <PlusIcon className="w-4 h-4" />
                            {t('keys.create_button')}
                        </button>
                    </div>
                    <div className="mt-6 text-gray-700">
                        <div className="inline-block mr-2 bg-red-700 text-white rounded-sm text-xs px-2 py-1 font-semibold">
                            {t('keys.warning.tag')}
                        </div>
                        <span>
                            <Trans i18nKey="keys.warning.text">
                                Ключи ProxyAPI не будут работать напрямую с OpenAI API или в приложениях, плагинах, библиотеках и т.п., в которых нельзя переопределить путь к API. Пожалуйста, внимательно ознакомьтесь с <a className="text-blue-600 hover:text-blue-500" href={docsUrl} target="_blank">документацией</a>.
                            </Trans>
                        </span>
                    </div>
                </div>
            </div>
            {
                deleteKey && (
                    <DialogModal
                        title={t('keys.delete.title')}
                        onClose={() => setDeleteKey(null)}
                    >
                        <div className="px-4 mt-4 mb-4 text-sm">
                            {t('keys.delete.text', { key: deleteKey.key_part })}
                        </div>
                        <div className="flex justify-end gap-2 px-4 mb-4">
                            <button
                                className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-red-600 hover:bg-red-500 focus:bg-red-500 focus:outline-none"
                                onClick={() => {
                                    handleKeyDelete(deleteKey);
                                }}
                            >
                                {t('keys.delete.positive_button')}
                            </button>
                            <button
                                className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-gray-600 hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
                                onClick={() => setDeleteKey(null)}
                            >
                                {t('common.cancel')}
                            </button>
                        </div>
                    </DialogModal>
                )
            }
            {
                createKey === true && (
                    <CreateKey onClose={() => setCreateKey(false)} />
                )
            }
        </>
    )
}