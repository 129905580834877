import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { HomeProvider } from './contexts/HomeContext';
import { QueryClientProvider, QueryClient } from 'react-query';
import './i18n';
import Root from './pages/Root';
import { Login, Register, Verify, ForgotPassword, ResetPassword } from './components/auth';
import Home from './components/home';
import Keys from './components/keys';
import Billing from './components/billling';
import Account from './components/account';
import PaymentResult from './components/billling/Result';
import Affiliate from './components/affiliate';
import SSO from './components/auth/SSO';
import Pro from './components/pro';

const AuthLayout = () => (
    <AuthProvider>
        <Outlet />
    </AuthProvider>
);

const HomeLayout = () => (
    <HomeProvider>
        <Outlet />
    </HomeProvider>
);

const router = createBrowserRouter([
    {
        element: <HomeLayout />,
        children: [
            {
                path: 'verify/:code',
                element: <Verify />
            },
            {
                path: 'forgot-password',
                element: <ForgotPassword />
            },
            {
                path: 'reset-password/:code',
                element: <ResetPassword />
            },
            {
                path: 'billing/result',
                element: <PaymentResult />
            },
            {
                element: <AuthLayout />,
                children: [
                    {
                        path: '/login',
                        element: <Login />
                    },
                    {
                        path: '/register',
                        element: <Register />
                    },
                    {
                        path: '/',
                        element: <Root />,
                        children: [
                            {
                                path: '/',
                                element: <Home />
                            },
                            {
                                path: '/keys',
                                element: <Keys />
                            },
                            {
                                path: '/billing',
                                element: <Billing />
                            },
                            {
                                path: '/account',
                                element: <Account />
                            },
                            {
                                path: '/partner',
                                element: <Affiliate />
                            },
                            {
                                path: '/login/sso',
                                element: <SSO />
                            },
                            {
                                path: '/pro',
                                element: <Pro />
                            },
                        ]
                    },
                ]
            },
        ]
    }
]);

function App() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <RouterProvider router={router} />
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;