import { useState } from 'react';
import { IPromoCodeUserResponse, PromoCodeType } from '../../types/affiliates'
import { useTranslation } from 'react-i18next';
import PromoCodeModal from './PromoCodeModal';

interface Props {
    promoCode: IPromoCodeUserResponse | null | undefined;
}

const PromoCode = ({ promoCode }: Props) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const { t } = useTranslation();

    if (promoCode === undefined) {
        return null;
    }

    if (promoCode === null) {
        return (
            <>
                <a onClick={() => setShowModal(true)} className="cursor-pointer text-gray-500 hover:text-gray-700 mt-1 text-xs">
                    {t('billing.affiliates.promo_codes.activate.title')}
                </a>
                {
                    showModal && (
                        <PromoCodeModal
                            onClose={() => setShowModal(false)}
                        />
                    )
                }
            </>
        )
    }

    return (
        <div className="text-xs text-gray-500 ">
            {
                promoCode.promo_code.type === PromoCodeType.DISCOUNT && !promoCode.payment_id ? (
                    <>{t('billing.affiliates.promo_codes.status.pending', {
                        discount: promoCode.promo_code.amount,
                    })}</>
                ) : (
                    <>{t('billing.affiliates.promo_codes.status.used')}</>
                )
            }
        </div>
    )
}

export default PromoCode