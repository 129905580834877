import { useEffect } from 'react';
import { useHomeContext } from '../../contexts/HomeContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import Balance from './Balance';
import PromoCodes from './PromoCodes';
import Payments from './Payments';
import Payouts from './Payouts';

export default function Affiliate() {
    const { t } = useTranslation();
    const {
        handleSetCurrentScreen,
        handleSetTitle,
    } = useHomeContext();
    const { user } = useAuthContext();

    useEffect(() => {
        handleSetCurrentScreen('partner');
        handleSetTitle(t('affiliate.title'));
    }, []);

    return (
        <div className="flex flex-col overflow-y-auto h-full pb-8 text-gray-900">
            <div className="w-full mx-auto mt-6 md:mt-12 md:max-w-2xl lg:max-w-3xl px-4 flex flex-col">
                {
                    user && user.is_affiliate && (
                        <>
                            <Balance />
                            <PromoCodes />
                            <Payments />
                            <Payouts />
                        </>
                    )
                }
            </div>
        </div>
    )
}