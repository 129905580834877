import { useTranslation } from 'react-i18next';
import { useHomeContext } from '../../contexts/HomeContext';
import Link from './PlainLink';

export default function BottomLinks() {
    const { t } = useTranslation();
    const { state: { currentScreen } } = useHomeContext();

    const links = [
        {
            label: t('menu.documentation'),
            url: `${process.env.REACT_APP_BRAND_URL}/docs`,
        },
        {
            label: t('menu.pricing'),
            url: `${process.env.REACT_APP_BRAND_URL}/pricing`,
        },
        {
            label: t('menu.community'),
            url: `https://community.proxyapi.ru`,
        },
        {
            label: t('menu.terms_of_service'),
            url: `${process.env.REACT_APP_BRAND_URL}/terms`,
        },
        {
            label: t('menu.privacy_policy'),
            url: `${process.env.REACT_APP_BRAND_URL}/privacy`,
        },
    ]

    const handleNavigation = (url: string) => {
        window.open(url, '_blank');
    }

    return (
        <>
            {links.map((link, index) => (
                <Link
                    key={index}
                    label={link.label}
                    onClick={() => handleNavigation(link.url)}
                />
            ))}
        </>
    )
}