import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { IAuthRegisterRequest } from '../../types/auth';
import { useAuthRegisterMutation } from '../../api/query';
import { IRemoteError, ServerFormError, RemoteErrorType } from '../../types/errors';
import { AnalyticsEvents } from '../../types/site';
import { useHomeContext } from '../../contexts/HomeContext';
import Cookies from 'js-cookie';

export default function Register() {
    const [success, setSuccess] = useState(false);
    const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);
    const { isAuthenticated } = useAuthContext();
    const { handleAnalyticsEvent } = useHomeContext();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setError
    } = useForm<IAuthRegisterRequest>();

    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const { mutate, error } = useAuthRegisterMutation()

    const password = watch('password');

    const onSubmit = (data: IAuthRegisterRequest) => {
        setRemoteError(null);
        data.attribution = Cookies.get('attribution') || null;
        mutate(data, {
            onSuccess: () => {
                setSuccess(true);
                handleAnalyticsEvent(AnalyticsEvents.REGISTRATION);
            }
        });
    };

    useEffect(() => {
        if (error) {
            if (error instanceof ServerFormError) {
                const rootError = error.getErrorForField('body');
                if (rootError) {
                    setRemoteError(rootError);
                }
                ['email', 'password', 'first_name', 'last_name'].forEach(field => {
                    const fieldError = error.getErrorForField(field);
                    if (fieldError) {
                        // @ts-ignore
                        setError(field, {
                            type: 'manual',
                            message: fieldError.msg
                        });
                    }
                });
            } else {
                setRemoteError(ServerFormError.unknown());
            }
        }
    }, [error])

    const passwordValidator = (value: string) => {
        if (!/\d/.test(value))
            return t('auth.register.password_invalid')
        if (!/[^\d]/.test(value))
            return t('auth.register.password_invalid')
        return true;
    };

    const termsUrl = `${process.env.REACT_APP_BRAND_URL}/terms`
    const privacyUrl = `${process.env.REACT_APP_BRAND_URL}/privacy`

    const { t } = useTranslation();
    return (
        <div className="flex min-h-screen flex-col items-center justify-center">
            <div className="w-96 px-4 sm:max-w-md md:px-0 overflow-hidden">
                <img src="/images/logo.svg" alt="ProxyAPI Logo" className="h-7 w-auto mb-12 mx-auto" />
                <h1 className="mb-10 text-4xl">{t('auth.register.title')}</h1>
                {remoteError && (
                    <div
                        className="mb-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                        role="alert"
                    >
                        {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`)}
                    </div>
                )}
                {success && (
                    <div
                        className="mb-4 rounded-sm border border-green-400 bg-green-100 px-4 py-3 text-green-700"
                        role="alert"
                    >
                        {t('auth.register.success_message')}
                    </div>
                )}
                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                    {/* email */}
                    <div className="mb-2">
                        <div className="relative">
                            <input
                                type="email"
                                id="email"
                                className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                                placeholder={" "}
                                aria-invalid={!!errors.email}
                                autoComplete="email"
                                disabled={success}
                                {...register('email', {
                                    required: String(t('errors.local.required_field')),
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: t('auth.register.email_invalid')
                                    }
                                })}
                            />
                            <label
                                htmlFor="email"
                                className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                            >
                                {t('auth.register.email_label')}
                            </label>
                        </div>
                        {errors.email && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.email.message}
                            </span>
                        )}
                    </div>

                    {/* first name */}
                    <div className="mb-2">
                        <div className="relative">
                            <input
                                type="first_name"
                                id="first_name"
                                className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                                placeholder={" "}
                                aria-invalid={!!errors.first_name}
                                autoComplete="given-name"
                                disabled={success}
                                {...register('first_name', {
                                    required: String(t('errors.local.required_field')),
                                    maxLength: {
                                        value: 50,
                                        message: t('errors.local.max_length', { count: 50 })
                                    }
                                })}
                            />
                            <label
                                htmlFor="first_name"
                                className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                            >
                                {t('auth.register.first_name_label')}
                            </label>
                        </div>
                        {errors.first_name && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.first_name.message}
                            </span>
                        )}
                    </div>

                    {/* last name */}
                    <div className="mb-2">
                        <div className="relative">
                            <input
                                type="last_name"
                                id="last_name"
                                className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                                placeholder={" "}
                                aria-invalid={!!errors.last_name}
                                autoComplete="family-name"
                                disabled={success}
                                {...register('last_name', {
                                    required: String(t('errors.local.required_field')),
                                    maxLength: {
                                        value: 50,
                                        message: t('errors.local.max_length', { count: 50 })
                                    }
                                })}
                            />
                            <label
                                htmlFor="last_name"
                                className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                            >
                                {t('auth.register.last_name_label')}
                            </label>
                        </div>
                        {errors.last_name && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.last_name.message}
                            </span>
                        )}
                    </div>

                    {/* password */}
                    <div className="mb-2">
                        <div className="relative">
                            <input
                                type="password"
                                id="password"
                                className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                                placeholder={" "}
                                aria-invalid={!!errors.password}
                                autoComplete="new-password"
                                disabled={success}
                                minLength={8}
                                maxLength={150}
                                {...register('password', {
                                    required: String(t('errors.local.required_field')),
                                    minLength: {
                                        value: 8,
                                        message: t('errors.local.min_length', { count: 8 })
                                    },
                                    maxLength: {
                                        value: 150,
                                        message: t('errors.local.max_length', { count: 150 })
                                    },
                                    validate: passwordValidator
                                })}
                            />
                            <label
                                htmlFor="password"
                                className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                            >
                                {t('auth.register.password_label')}
                            </label>
                        </div>
                        {errors.password && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.password.message}
                            </span>
                        )}
                    </div>

                    {/* password confirm */}
                    <div className="mb-6">
                        <div className="relative">
                            <input
                                type="password"
                                id="password_confirm"
                                className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                                placeholder={" "}
                                aria-invalid={!!errors.password_confirm}
                                autoComplete="new-password"
                                disabled={success}
                                {...register('password_confirm', {
                                    required: String(t('errors.local.required_field')),
                                    validate: (value) => value === password || String(t('auth.register.password_confirmation_invalid'))
                                })}
                            />
                            <label
                                htmlFor="password_confirm"
                                className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                            >
                                {t('auth.register.password_confirmation_label')}
                            </label>
                        </div>
                        {errors.password_confirm && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.password_confirm.message}
                            </span>
                        )}
                    </div>

                    {/* terms */}
                    <div className="mb-6">
                        <div className="flex">
                            <input
                                type="checkbox"
                                id="terms"
                                className="peer h-4 w-4 border-gray-300 rounded-sm focus:ring-blue-600 text-blue-600 focus:outline-none mt-0.5"
                                disabled={success}
                                {...register('terms', {
                                    required: String(t('errors.local.required_field')),
                                })}
                            />
                            <label
                                htmlFor="terms"
                                className="ml-2 block text-sm text-gray-900"
                            >
                                <span dangerouslySetInnerHTML={{
                                    __html:
                                        t('auth.register.terms_agree_label', {
                                            terms: `
                                        <a href="${termsUrl}" target="_blank" class="text-blue-600 hover:text-blue-500">
                                            ${t('auth.register.terms_agree_terms')}
                                        </a>
                                    `,
                                            privacy: `
                                        <a href="${privacyUrl}" target="_blank" class="text-blue-600 hover:text-blue-500">
                                            ${t('auth.register.terms_agree_privacy')}
                                        </a>
                                    `
                                        })
                                }} />
                            </label>
                        </div>
                        {errors.terms && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.terms.message}
                            </span>
                        )}
                    </div>

                    <div className="mb-6">
                        <button
                            type="submit"
                            disabled={success}
                            className="w-full justify-center py-3 px-2.5 rounded-sm text-sm font-medium text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
                        >
                            {t('auth.register.submit_button')}
                        </button>
                    </div>

                    <div className="mb-6">
                        <div className="flex items-center justify-center">
                            <div className="text-sm text-gray-600">
                                {t('auth.register.already_have_account')}
                            </div>
                            <Link to="/login" className="m-1 text-sm text-blue-600 hover:text-blue-500">
                                {t('auth.register.login')}
                            </Link>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}