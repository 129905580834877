import { MouseEvent } from "react";

interface Props {
    icon: JSX.Element;
    label: string;
    selected: boolean;
    onClick?: (e: MouseEvent) => void;
}

export default function Link({ icon, label, selected, onClick }: Props) {
    const className = `flex cursor-pointer items-center gap-3 rounded-md py-3 px-3 text-sm text-gray-700 transition-colors duration-200 hover:bg-gray-100 ${selected ? 'bg-gray-100' : ''}`

    const handleOnClick = (e: MouseEvent) => {
        onClick && onClick(e);
    };

    return (
        <a onClick={handleOnClick} className={className}>
            {icon}
            <span>{label}</span>
        </a>
    )

}