import { useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IAuthForgotPasswordRequest } from '../../types/auth';
import { useAuthForgotPasswordRequestMutation } from '../../api/query';
import { IRemoteError, ServerFormError, RemoteErrorType } from '../../types/errors';

export default function ForgotPassword() {
    const [success, setSuccess] = useState(false);
    const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setError
    } = useForm<IAuthForgotPasswordRequest>();

    const { mutate, error } = useAuthForgotPasswordRequestMutation()


    const onSubmit = (data: IAuthForgotPasswordRequest) => {
        setRemoteError(null);
        mutate(data, {
            onSuccess: () => {
                setSuccess(true);
            }
        });
    };

    useEffect(() => {
        if (error) {
            if (error instanceof ServerFormError) {
                const rootError = error.getErrorForField('body');
                if (rootError) {
                    setRemoteError(rootError);
                }
                ['email'].forEach(field => {
                    const fieldError = error.getErrorForField(field);
                    if (fieldError) {
                        // @ts-ignore
                        setError(field, {
                            type: 'manual',
                            message: fieldError.msg
                        });
                    }
                });
            } else {
                setRemoteError(ServerFormError.unknown());
            }
        }
    }, [error])

    const { t } = useTranslation();
    return (
        <div className="flex min-h-screen flex-col items-center justify-center">
            <div className="w-96 px-4 sm:max-w-md md:px-0 overflow-hidden">
                <img src="/images/logo.svg" alt="ProxyAPI Logo" className="h-7 w-auto mb-12 mx-auto" />
                <h1 className="mb-10 text-4xl">{t('auth.forgot.request.title')}</h1>
                {remoteError && (
                    <div
                        className="mb-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                        role="alert"
                    >
                        {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`)}
                    </div>
                )}
                {success && (
                    <div
                        className="mb-4 rounded-sm border border-green-400 bg-green-100 px-4 py-3 text-green-700"
                        role="alert"
                    >
                        {t('auth.forgot.request.success_message')}
                    </div>
                )}
                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                    {/* email */}
                    <div className="mb-6">
                        <div className="relative">
                            <input
                                type="email"
                                id="email"
                                className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                                placeholder={" "}
                                aria-invalid={!!errors.email}
                                autoComplete="email"
                                disabled={success}
                                {...register('email', {
                                    required: String(t('errors.local.required_field')),
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: t('auth.forgot.request.email_invalid')
                                    }
                                })}
                            />
                            <label
                                htmlFor="email"
                                className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
                            >
                                {t('auth.forgot.request.email_label')}
                            </label>
                        </div>
                        {errors.email && (
                            <span role="alert" className="mt-1 text-sm text-red-600">
                                {errors.email.message}
                            </span>
                        )}
                    </div>

                    <div className="mb-6">
                        <button
                            type="submit"
                            disabled={success}
                            className="w-full justify-center py-3 px-2.5 rounded-sm text-sm font-medium text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
                        >
                            {t('auth.forgot.request.submit_button')}
                        </button>
                    </div>

                    <div className="mb-6">
                        <div className="flex items-center justify-center">
                            <Link to="/login" className="text-sm text-blue-600 hover:text-blue-500">
                                {t('common.back')}
                            </Link>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}