
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Nav from '../components/nav';
import MobileNav from '../components/nav/MobileNav';

export default function Root() {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <>
            <div className="flex h-screen">
                <Nav isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
                <div className="flex h-full w-full flex-1 flex-col">
                    <MobileNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    <div className="transition-width relative flex h-full w-full flex-1 flex-col items-stretch overflow-hidden bg-white pt-10 md:pt-0">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}