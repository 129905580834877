import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAffiliatesPromoCodesMeQuery, usePaymentListQuery, useUsageAggregatedQuery } from '../../api/query';
import { useAuthContext } from '../../contexts/AuthContext';
import { useHomeContext } from '../../contexts/HomeContext';
import { IPromoCodeUserResponse } from '../../types/affiliates';
import { IPayment } from '../../types/payment';
import { IUsageAggregated } from '../../types/usage';
import { formatPrice } from '../../utils/helpers';
import PromoCode from './PromoCode';
import Topup from './Topup';

export default function Billing() {
  const { t } = useTranslation();
  const {
    handleSetCurrentScreen,
    handleSetTitle,
  } = useHomeContext();
  const { user } = useAuthContext();

  const [usage, setUsage] = useState<IUsageAggregated | null>(null);
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [showTopupModal, setShowTopupModal] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<IPromoCodeUserResponse | null | undefined>(undefined);

  const { data: usageData } = useUsageAggregatedQuery();
  const { data: paymentsData } = usePaymentListQuery();
  const { data: promoCodeData } = useAffiliatesPromoCodesMeQuery();

  useEffect(() => {
    handleSetCurrentScreen('billing');
    handleSetTitle(t('billing.title'));
  }, []);

  useEffect(() => {
    if (usageData) {
      setUsage(usageData);
    }
  }, [usageData]);

  useEffect(() => {
    if (paymentsData) {
      setPayments(paymentsData);
    }
  }, [paymentsData]);

  useEffect(() => {
    if (promoCodeData !== undefined) {
      setPromoCode(promoCodeData);
    }
  }, [promoCodeData]);

  const onClose = () => {
    setShowTopupModal(false);
  }

  return (
    <>
      <div className="flex flex-col overflow-y-auto h-full pb-8 text-gray-900">
        <div className="w-full mx-auto mt-6 md:mt-12 md:max-w-2xl lg:max-w-3xl px-4 flex flex-col">

          <h3 className="text-4xl py-3 mb-6">{t('billing.balance')}</h3>

          <div className="rounded-md border border-gray-200 p-2 flex items-center justify-between mb-6">
            <div className="flex flex-col ml-2 justify-start">
              <div className="text-2xl text-blue-600 font-normal">{formatPrice(user?.balance ?? 0, 2)}</div>
              <PromoCode promoCode={promoCode} />
            </div>
            <button
              className="justify-center py-3 px-2.5 rounded-sm text-sm font-medium text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
              onClick={() => setShowTopupModal(true)}
            >
              {t('billing.topup_button')}
            </button>
          </div>
          <h3 className="text-4xl mb-6 py-3">{t('billing.usage.title')}</h3>
          {usage &&
            <div className="mb-6 text-gray-700 text-sm">
              {
                ['today', 'yesterday', 'last_7_days', 'month_start', 'last_30_days', 'year_start'].map((key) => (
                  <div className="flex items-center justify-between border-b border-dashed border-gray-200 py-2" key={key}>
                    <div>{t(`billing.usage.head.${key}`)}</div>
                    <div>{formatPrice(usage[key as keyof IUsageAggregated])}</div>
                  </div>
                ))
              }
            </div>
          }
          <h3 className="text-4xl mb-6 py-3">{t('billing.payments.title')}</h3>
          <table className="w-full table-auto text-sm">
            <thead className="text-left">
              <tr className="border-b border-gray-200">
                <th className="py-2 font-semibold">{t('billing.payments.head.date')}</th>
                <th className="py-2 font-semibold text-end">{t('billing.payments.head.amount')}</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-sm">
              {payments.map((payment, index) => (
                <tr key={index} className="border-b border-gray-200">
                  <td className="py-2">{new Date(payment.created_at).toLocaleDateString()}</td>
                  <td className="py-2 text-end">{formatPrice(payment.amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showTopupModal && user &&
        <Topup onClose={onClose} promoCode={promoCode} />
      }
    </>
  )
}