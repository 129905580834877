
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHomeContext } from "../../contexts/HomeContext";

export default function Home() {
    const {
        handleSetCurrentScreen,
        handleSetTitle,
    } = useHomeContext();

    const { t } = useTranslation();

    useEffect(() => {
        handleSetCurrentScreen('home');
        handleSetTitle(t('home.title'));
    }, []);

    return (
        <>
            <div className="flex flex-col overflow-y-auto h-full pb-8 text-gray-900">
                <div className="w-full mx-auto mt-6 md:mt-12 md:max-w-2xl lg:max-w-3xl px-4 flex flex-col">
                    <h3 className="text-4xl mb-6 py-3">{t('home.title')}</h3>
                    <p className="mb-6 text-gray-700 font-light">
                        Добро пожаловать в личный кабинет сервиса ProxyAPI!<br />
                        В меню слева вы можете редактировать свой аккаунт, создавать и отзывать ключи API и просматривать статистику использования и платежей.<br />
                        На этой странице мы будем публиковать новости и обновления сервиса.
                    </p>
                </div>
            </div>
        </>
    )
}