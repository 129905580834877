import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaymentSubscriptionsListQuery } from '../../api/query';
import { useHomeContext } from '../../contexts/HomeContext';
import { ISubscription, KnownProducts } from '../../types/subscription';
import { formatPrice } from '../../utils/helpers';
import Spinner from '../ui/Spinner';
import SubscribeModal from './SubscribeModal';

const StatusBlock = ({ subscription }: { subscription: ISubscription | null }) => {
  const { t } = useTranslation();

  const color = subscription && subscription.auto_renew ? 'text-blue-600' : 'text-gray-600';

  let title = ""
  if (!subscription) {
    title = t('pro.manage.status.inactive');
  } else {
    if (subscription.auto_renew) {
      title = t('pro.manage.status.active');
    } else {
      title = t('pro.manage.status.cancelled');
    }
  }

  return (
    <div className="flex flex-col ml-2 justify-start">
      <div className={`text-2xl ${color} font-normal`}>{title}</div>
      <PriceBlock subscription={subscription} />
    </div>
  )
}

const PriceBlock = ({ subscription }: { subscription: ISubscription | null }) => {
  const { t } = useTranslation();

  if (subscription && subscription.auto_renew) {
    return (
      <div className="text-xs text-gray-600">
        {t('pro.manage.next_payment', {
          date: new Date(subscription.ended_at).toLocaleDateString('ru', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }),
          price: formatPrice(subscription.product.price, 0)
        })}
      </div>
    );
  }

  if (subscription && !subscription.auto_renew) {
    return (
      <div className="text-xs text-gray-600">
        {t('pro.manage.expires', {
          date: new Date(subscription.ended_at).toLocaleDateString('ru', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        })}
      </div>
    )
  }

  return (
    <div className="text-xs text-gray-600">
      {t('pro.manage.price', {
        price: formatPrice(1500, 0)
      })}
    </div>
  )
}

const UpdateBlock = ({ subscription }: { subscription: ISubscription | null }) => {
  const [showPopup, setShowopup] = useState<boolean>(false);
  const { t } = useTranslation();

  let buttonTitle = ""
  if (!subscription) {
    buttonTitle = t('pro.manage.buttons.activate');
  } else {
    if (subscription.auto_renew) {
      buttonTitle = t('pro.manage.buttons.deactivate');
    } else {
      buttonTitle = t('pro.manage.buttons.reactivate');
    }
  }

  let buttonColor = subscription && subscription.auto_renew ? 'bg-gray-400 hover:bg-gray-500 focus:bg-gray-400' : 'bg-blue-600 hover:bg-blue-500 focus:bg-blue-500';

  return (
    <div className="flex flex-col ml-2 justify-end">
      <button
        className={`justify-center py-3 px-2.5 rounded-sm text-sm font-medium text-white transform transition-colors duration-200 focus:outline-none ${buttonColor}`}
        onClick={() => setShowopup(true)}
      >
        {buttonTitle}
      </button>

      {
        showPopup &&
        <SubscribeModal onClose={() => setShowopup(false)} subscription={subscription} />
      }
    </div>
  )
}

const Pro = () => {
  const [activeSubscription, setActiveSubscription] = useState<ISubscription | null>(null); // [1
  const [endedAt, setEndedAt] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { data: subscriptionsData, isLoading: subscriptionsLoading } = usePaymentSubscriptionsListQuery();

  const { t } = useTranslation();
  const {
    handleSetCurrentScreen,
    handleSetTitle,
  } = useHomeContext();

  useEffect(() => {
    handleSetCurrentScreen('pro');
    handleSetTitle(t('pro.title'));
  }, []);

  const features = t('pro.features', { returnObjects: true }) as string[];
  const openProPage = () => {
    window.open(`${process.env.REACT_APP_BRAND_URL}/pro`, '_blank');
  }

  useEffect(() => {
    if (subscriptionsData) {
      for (const sub of subscriptionsData) {
        if (sub.product.sku === KnownProducts.PRO) {
          setActiveSubscription(sub);
          return;
        }
      }
      setActiveSubscription(null);
    }
  }, [subscriptionsData]);

  useEffect(() => {
    setIsLoading(subscriptionsLoading);
  }, [subscriptionsLoading]);

  return isLoading ? <Spinner className="text-blue-600 w-10 h-10 mx-auto my-auto" /> : (
    <>
      <div className="flex flex-col overflow-y-auto h-full pb-8 text-gray-900">
        <div className="w-full mx-auto mt-6 md:mt-12 md:max-w-2xl lg:max-w-3xl px-4 flex flex-col">
          <h3 className="text-4xl py-3 mb-6">{t('pro.subtitle')}</h3>

          <p className="p-4 bg-gray-100 text-sm rounded-md mb-6">
            <div className="inline-block mr-2 bg-yellow-300 text-black rounded-sm text-xs px-2 py-1 font-semibold">
              {t('pro.warning.tag')}
            </div>
            <span>
              {t('pro.warning.text')}
            </span>
          </p>

          <div className="rounded-md border border-gray-200 p-2 flex items-center justify-between mb-6">
            <StatusBlock subscription={activeSubscription} />
            <UpdateBlock subscription={activeSubscription} />
          </div>

          <p className="mb-6 text-gray-700">
            {t('pro.description')}
          </p>
          {features.map((feature: string, index: number) => (
            <div className="flex items-center gap-2 mt-2" key={index}>
              <CheckCircleIcon className="h-5 w-5 text-blue-600" />
              {feature}
            </div>
          ))}
          <div className="mt-6">
            <button
              className="justify-center py-3 px-2.5 rounded-sm text-sm font-medium text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
              onClick={openProPage}
            >
              {t('pro.cta')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pro;