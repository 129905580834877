import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePaymentCreateMutation } from "../../api/query";
import { IPromoCodeUserResponse, PromoCodeType } from "../../types/affiliates";
import { IRemoteError, RemoteErrorType, ServerFormError } from "../../types/errors";
import { IPaymentCreateRequest } from "../../types/payment";
import { formatPrice } from "../../utils/helpers";
import DialogModal from "../dialog";

interface Props {
  onClose: () => void;
  promoCode: IPromoCodeUserResponse | null | undefined;
}

const PromoCodeNotice = ({ promoCode, amount }: {
  promoCode: IPromoCodeUserResponse | null | undefined,
  amount: number
}) => {
  const [finalAmount, setFinalAmount] = useState<number>(0);

  useEffect(() => {
    if (promoCode && promoCode.promo_code.type === PromoCodeType.DISCOUNT) {
      setFinalAmount(amount - amount * promoCode.promo_code.amount / 100);
    }
  }, [amount, promoCode]);

  const { t } = useTranslation();

  if (
    promoCode === undefined ||
    promoCode === null ||
    promoCode.promo_code.type !== PromoCodeType.DISCOUNT ||
    promoCode.payment_id ||
    !amount
  ) {
    return null;
  }

  return (
    <div className="text-xs px-4 my-4 text-right">
      <>
        {t('billing.affiliates.promo_codes.topup_notice.discount', {
          discount: promoCode.promo_code.amount,
        })}<br />
        {t('billing.affiliates.promo_codes.topup_notice.amount', {
          amount: formatPrice(finalAmount),
        })}
      </>
    </div>
  )

}

export default function Topup({ onClose, promoCode }: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  const [remoteError, setRemoteError] = useState<IRemoteError | null>(null);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch
  } = useForm<IPaymentCreateRequest>();

  const { mutate, error } = usePaymentCreateMutation();

  const onSubmit = (data: IPaymentCreateRequest) => {
    setRemoteError(null);
    data.attribution = Cookies.get('attribution') || null;
    mutate(data, {
      onSuccess: (remoteData) => {
        if (formRef.current) {
          const { sum, clientid, orderid, service_name, client_email, sign, url } = remoteData;
          const inputFields = formRef.current.querySelectorAll('input[name]') as NodeListOf<HTMLInputElement>;
          inputFields.forEach((input: HTMLInputElement) => {
            switch (input.name) {
              case 'sum':
                input.value = sum;
                break;
              case 'clientid':
                input.value = clientid;
                break;
              case 'service_name':
                input.value = service_name;
                break;
              case 'client_email':
                input.value = client_email;
                break;
              case 'sign':
                input.value = sign;
                break;
              case 'orderid':
                input.value = orderid;
                break;
              default:
                break;
            }
          });
          formRef.current.action = url;
          formRef.current.submit();
        }
      }
    });
  }

  useEffect(() => {
    if (error) {
      if (error instanceof ServerFormError) {
        const rootError = error.getErrorForField('body');
        if (rootError) {
          setRemoteError(rootError);
        }
        ['amount'].forEach(field => {
          const fieldError = error.getErrorForField(field);
          if (fieldError) {
            // @ts-ignore
            setError(field, {
              type: 'manual',
              message: fieldError.msg
            });
          }
        });
      } else {
        setRemoteError(ServerFormError.unknown());
      }
    }
  }, [error])

  const amountField = watch('amount');

  const returnUrl = `${process.env.REACT_APP_APP_URL}/billing/result`

  return (
    <DialogModal
      title={t('billing.topup.title')}
      onClose={() => onClose()}
    >
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        {remoteError && (
          <div
            className="m-4 rounded-sm border border-red-400 bg-red-100 px-4 py-3 text-red-700"
            role="alert"
          >
            {remoteError.type === RemoteErrorType.unknown ? remoteError.msg : t(`errors.remote.${remoteError.type}`)}
          </div>
        )}
        <div className="px-4 mt-4 mb-4">
          <div className="relative">
            <input
              type="number"
              id="amount"
              className="peer block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
              placeholder={" "}
              aria-invalid={!!errors.amount}
              autoComplete="Off"
              step={100}
              {...register('amount', {
                required: String(t('errors.local.required_field')),
                min: {
                  value: 200,
                  message: t('billing.topup.amount_invalid', { amount: 200 })
                }
              })}
            />
            <label
              htmlFor="amount"
              className="absolute left-2.5 top-2.5 z-10 origin-[0] transform -translate-y-5 scale-75 bg-white px-1 text-gray-600 text-sm peer-placeholder-shown:translate-y-0.5 peer-placeholder-shown:scale-100 peer-focus:-translate-y-5 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:text-sm transition-all"
            >
              {t('billing.topup.amount_label')}
            </label>
          </div>
          {errors.amount && (
            <span role="alert" className="mt-1 text-sm text-red-600">
              {errors.amount.message}
            </span>
          )}
        </div>
        <PromoCodeNotice promoCode={promoCode} amount={amountField} />
        <div className="flex justify-end gap-2 px-4 mb-4">
          <button
            className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 focus:outline-none"
            type="submit"
          >
            {t('billing.topup.submit_button')}
          </button>
          <button
            className="py-2 px-2.5 rounded-sm text-xs text-white transform transition-colors duration-200 bg-gray-600 hover:bg-gray-500 focus:bg-gray-500 focus:outline-none"
            onClick={() => onClose()}
          >
            {t('common.cancel')}
          </button>

        </div>
        <div className="px-4 mb-4">
          <img src="/images/cards_on_white.svg" alt="Payment methods" className="h-7 ml-auto" />
        </div>
      </form>
      <form method="POST" ref={formRef}>
        <input type="hidden" name="sum" value="" />
        <input type="hidden" name="clientid" value="" />
        <input type="hidden" name="orderid" value="" />
        <input type="hidden" name="service_name" value="" />
        <input type="hidden" name="client_email" value="" />
        <input type="hidden" name="sign" value="" />
        <input type="hidden" name="user_result_callback" value={returnUrl} />
      </form>
    </DialogModal>
  )
}