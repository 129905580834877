import { CheckIcon, Square2StackIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

interface Props {
    api_key: string;
}

export default function KeyFieldWithCopy({ api_key }: Props) {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(api_key);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    return (
        <div className="relative w-full">
            <input
                className="block w-full px-2.5 py-3 border border-gray-300 rounded-sm focus:border-blue-600 focus:outline-none sm:text-sm"
                readOnly={true}
                value={api_key}
            />
            <div className="absolute inset-y-0 right-0 flex items-center px-2.5">
                <button
                    className="text-gray-900 hover:text-gray-500 focus:outline-none"
                    onClick={handleCopy}
                >
                    {
                        copied
                            ? <CheckIcon className="w-4 h-4" />
                            : <Square2StackIcon className="w-4 h-4" />
                    }
                </button>
            </div>
        </div>
    );
}