import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { AnalyticsEvents } from '../types/site';

interface IHomeContextState {
    currentScreen: string | null;
    title: string;
}

const initialState: IHomeContextState = {
    currentScreen: null,
    title: process.env.REACT_APP_NAME ?? '',
};

interface IHomeContext {
    state: IHomeContextState;
    handleSetCurrentScreen: (page: string | null) => void;
    handleSetTitle: (title: string) => void;
    handleAnalyticsEvent: (id: AnalyticsEvents) => void;
}

const HomeContext = createContext<IHomeContext | undefined>(undefined);

interface ProviderProps {
    children: ReactNode;
}

const HomeProvider: React.FC<ProviderProps> = ({ children }) => {
    const [state, setState] = useState<IHomeContextState>(initialState);
    const { pathname, search } = useLocation();

    const handleSetCurrentScreen = (page: string | null) => {
        setState((prevState) => ({
            ...prevState,
            currentScreen: page
        }));
    };

    const handleSetTitle = (title: string) => {
        setState((prevState) => ({
            ...prevState,
            title
        }));
    };

    const handleAnalyticsEvent = (id: AnalyticsEvents) => {
        // @ts-ignore
        ym(process.env.REACT_APP_YANDEX_METRIKA_ID, 'reachGoal', id);
    }

    const handleHit = (url: string) => {
        // @ts-ignore
        ym(process.env.REACT_APP_YANDEX_METRIKA_ID, 'hit', url);
    }

    useEffect(() => {
        document.title = state.title;
    }, [state.title]);

    useEffect(() => {
        const url = pathname + search;
        handleHit(url);
    }, [pathname, search])

    return (
        <HomeContext.Provider value={{ state, handleSetCurrentScreen, handleSetTitle, handleAnalyticsEvent }}>
            {children}
        </HomeContext.Provider>
    );
};

const useHomeContext = () => {
    const context = useContext(HomeContext);
    if (context === undefined) {
        throw new Error('useHomeContext should be used inside HomeProvider');
    }
    return context;
};

export { useHomeContext, HomeProvider };