import { MouseEvent } from "react";

interface Props {
    label: string;
    onClick?: (e: MouseEvent) => void;
}

export default function Link({ label, onClick }: Props) {
    const className = `flex cursor-pointer text-sm text-gray-700 px-3 py-1 transition-colors duration-200 hover:text-blue-600`

    const handleOnClick = (e: MouseEvent) => {
        onClick && onClick(e);
    };

    return (
        <a onClick={handleOnClick} className={className}>
            <span>{label}</span>
        </a>
    )

}