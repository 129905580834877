export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export class Endpoints {
  static auth = {
    register: {
      path: '/auth/register',
      method: HttpMethod.POST,
      requiresAuth: false,
    },
    verify: {
      submit: {
        path: (code: string) => `/auth/verify/${code}`,
        method: HttpMethod.POST,
        requiresAuth: false,
      },
      resend: {
        path: '/auth/verify',
        method: HttpMethod.POST,
        requiresAuth: false,
      },
    },
    forgotPassword: {
      submit: {
        path: (code: string) => `/auth/reset-password/${code}`,
        method: HttpMethod.POST,
        requiresAuth: false,
      },
      request: {
        path: '/auth/forgot-password',
        method: HttpMethod.POST,
        requiresAuth: false,
      },
    },
    login: {
      path: '/auth/login',
      method: HttpMethod.POST,
      requiresAuth: false,
    },
    me: {
      path: '/auth/me',
      method: HttpMethod.GET,
      requiresAuth: true,
    },
    changePassword: {
      path: '/auth/change-password',
      method: HttpMethod.POST,
      requiresAuth: true,
    },
    updateProfile: {
      path: '/auth',
      method: HttpMethod.PUT,
      requiresAuth: true,
    },
    changeEmail: {
      path: '/auth/change-email',
      method: HttpMethod.POST,
      requiresAuth: true,
    },
    refreshToken: {
      path: '/auth/refresh-token',
      method: HttpMethod.POST,
      requiresAuth: false,
    },
    sso: {
      path: '/auth/sso',
      method: HttpMethod.POST,
      requiresAuth: true,
    },
  }
  static api_key = {
    create: {
      path: '/keys',
      method: HttpMethod.POST,
      requiresAuth: true,
    },
    list: {
      path: '/keys',
      method: HttpMethod.GET,
      requiresAuth: true,
    },
    delete: {
      path: (id: number) => `/keys/${id}`,
      method: HttpMethod.DELETE,
      requiresAuth: true,
    },
    update: {
      path: (id: number) => `/keys/${id}`,
      method: HttpMethod.PUT,
      requiresAuth: true,
    },
    models: {
      list: {
        path: '/keys/models',
        method: HttpMethod.GET,
        requiresAuth: true,
      },
    },
  }
  static usage = {
    list: {
      path: '/usage',
      method: HttpMethod.GET,
      requiresAuth: true,
    },
  }
  static payment = {
    list: {
      path: '/payments',
      method: HttpMethod.GET,
      requiresAuth: true,
    },
    create: {
      path: '/payments',
      method: HttpMethod.POST,
      requiresAuth: true,
    },
  }
  static subscriptions = {
    list: {
      path: '/subscriptions',
      method: HttpMethod.GET,
      requiresAuth: true,
    },
    activate: {
      path: (sku: string) => `/subscriptions/${sku}`,
      method: HttpMethod.POST,
      requiresAuth: true,
    },
    deactivate: {
      path: (sku: string) => `/subscriptions/${sku}`,
      method: HttpMethod.DELETE,
      requiresAuth: true,
    },
    reactivate: {
      path: (sku: string) => `/subscriptions/${sku}`,
      method: HttpMethod.PUT,
      requiresAuth: true,
    },
  }
  static affiliate = {
    create: {
      path: '/affiliates',
      method: HttpMethod.POST,
      requiresAuth: true,
    },
    me: {
      path: '/affiliates/me',
      method: HttpMethod.GET,
      requiresAuth: true,
    },
    payments: {
      list: {
        path: '/affiliates/me/payments',
        method: HttpMethod.GET,
        requiresAuth: true,
      }
    },
    payouts: {
      list: {
        path: '/affiliates/me/payouts',
        method: HttpMethod.GET,
        requiresAuth: true,
      }
    },
    promo_code: {
      me: {
        path: '/affiliates/promo_codes/me',
        method: HttpMethod.GET,
        requiresAuth: true,
      },
      apply: {
        path(code: string) {
          return `/affiliates/promo_codes/${code}`
        },
        method: HttpMethod.POST,
        requiresAuth: true,
      },
      create: {
        path: '/affiliates/promo_codes',
        method: HttpMethod.POST,
        requiresAuth: true,
      },
      list: {
        path: '/affiliates/promo_codes',
        method: HttpMethod.GET,
        requiresAuth: true,
      },
    }
  }
}