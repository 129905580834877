export enum RemoteErrorType {
    unknown = "unknown",
    emailTaken = "email_taken",
    emailNotFound = "email_not_found",
    emailAlreadyVerified = "email_already_verified",
    verificationCodeNotFound = "verification_code_not_found",
    verificationCodeExpired = "verification_code_expired",
    invalidCredentials = "invalid_credentials",
    emailNotVerified = "email_not_verified",
    emailAlreadyExists = "email_already_exists",
    apiKeyLimitReached = "api_key_limit_reached",
    promoCodeNotFound = "promo_code_not_found",
    promoCodeHasBeenUsed = "promo_code_has_been_used",
    promoCodeUserAlreadyHas = "promo_code_user_already_has",
    balanceInsufficient = "balance_insufficient",
    userBanned = "user_banned",
}

export interface IRemoteError {
    type: RemoteErrorType;
    loc: string[];
    msg: string;
}

export interface IServerErrorDetail {
    detail: IRemoteError[]
}

export function followsIRemoteError(obj: any): obj is IRemoteError {
    return (
        typeof obj === 'object' &&
        obj !== null &&
        'type' in obj &&
        'loc' in obj &&
        'msg' in obj
    );
}

export function followsIServerErrorDetail(obj: any): obj is IServerErrorDetail {
    if ('detail' in obj && Array.isArray(obj.detail)) {
        return obj.detail.every(followsIRemoteError);
    }
    return false;
}

export class ServerFormError extends Error {
    data: IServerErrorDetail;
    constructor(data: IServerErrorDetail, message: string | undefined) {
        super(message);
        this.name = 'ServerFormError';
        this.data = data;
    }

    getErrorForField(field: string): IRemoteError | undefined {
        // find all errors that match the field
        const errors = this.data.detail.filter((error) => error.loc.includes(field));
        for (let error of errors) {
            if (field === "body" && error.loc.length > 1) {
                continue
            }
            error.type = RemoteErrorType[error.type as keyof typeof RemoteErrorType] ?? RemoteErrorType.unknown;
            return error;
        }

        // if no errors match the field, return undefined
        return undefined;
    }

    static unknown(): IRemoteError {
        return {
            type: RemoteErrorType.unknown,
            loc: [],
            msg: "Unknown error",
        }
    }
}

export class UnauthorizedError extends Error {
    constructor(message: string | undefined) {
        super(message);
        this.name = 'UnauthorizedError';
    }
}